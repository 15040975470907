import React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { getBuildingsList } from '../../utils/api.js';
import { showErrorAlert } from '../../utils/alerts.js';

const Buildings = (props) => {
  const { history } = props;
  const [buildings, setBuildings] = useState([]);

  const getAllBuildings = async () => {
    try {
      const data = await getBuildingsList();

      setBuildings(data);
    } catch (error) {
      showErrorAlert('Error', error.message);
    }
  };

  useEffect(() => {
    getAllBuildings();
  }, []);

  return (
    <>
      <div className="mb-3 row w-100 text-form">
        <h2>Administrar edificios</h2>
      </div>
      <div className="container table-buildings">
        <table className="table table-striped">
          <thead className="text-center font-weight-bold">
            <tr>
              <th scope="col">Nombre</th>
              <th scope="col">Ciudad</th>
              <th scope="col">Acción</th>
            </tr>
          </thead>
          <tbody>
            {buildings.map((building) => {
              const { id, name, city } = building;
              return (
                <tr key={id}>
                  <td className="align-middle">{name}</td>
                  <td className="align-middle">{city}</td>
                  <td className="align-middle">
                    <button
                      style={{ marginRight: '15px' }}
                      type="button"
                      className="btn"
                      onClick={() => {
                        history.push(`/admin-dashboard/buildings/${id}/apartments`);
                      }}
                    >
                      <span className="material-icons">edit</span>
                    </button>

                    <button
                      type="button"
                      className="btn"
                      onClick={() => {
                        history.push({
                          pathname: `/admin-dashboard/buildings/${id}`,
                          state: building,
                        });
                      }}
                    >
                      <span className="material-icons">visibility</span>
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
Buildings.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};
export default Buildings;
