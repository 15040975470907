import React, { Component } from "react";
import PropTypes from "prop-types";
/** Components */
import { Logo } from "../logo";
import Modal from "react-responsive-modal";

import "../assets/css/terminos-y-condiciones.css";

class TerminosYCondiciones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true,
    };
  }

  render() {
    const { showModal } = this.state;

    return (
      <Modal
        open={showModal}
        onClose={this.props.onModalClosed.bind(this)}
        classNames={{ overlay: "background-modal" }}
        center
      >
        <div className="modal-container">
          <h5 className="modal-title">
            <Logo isBgBlue={true} className="App-logo w-75" />
          </h5>

          <button
            type="button"
            className="close modal-close"
            data-dismiss="modal"
            onClick={this.props.onModalClosed.bind(this)}
          >
            &times;
          </button>

          <h2>Condiciones de servicio</h2>
          <p className="text-justify">
            Por favor lea estas condiciones de uso detenidamente, antes de
            utilizar los servicios ofrecidos
          </p>
          <p className="text-justify">
            El presente documento fija las pautas, condiciones y términos en los
            que los clientes pueden acceder a los productos y servicios que
            provee la sociedad B SMART EU. La utilización del servicio por parte
            del usuario, es una declaración formal por parte del mismo, de la
            aceptación de todos los términos y condiciones incluidos en el
            presente documento.
          </p>
          <p className="text-justify">
            Este Acuerdo, establece los términos y condiciones de uso de
            nuestros servicios, Si usted hace uso de nuestros servicios
            automáticamente acepta que ha leído estos TÉRMINOS Y CONDICIONES,
            los entiende y los acepta.
          </p>
          <p className="text-justify">
            Si usted no está de acuerdo con estas condiciones, NO podrá hacer
            uso de nuestros servicios.
          </p>
          <p className="text-justify">
            A continuación, se encuentran los términos y condiciones que rigen
            cualquier uso de los servicios ofrecidos
          </p>

          <h4>GENERALIDADES:</h4>

          <p className="text-justify">
            BSMART es un servicio prestado por B SMART E.U, (En Adelante BSMART)
            con más de 9 años de experiencia en investigación, desarrollo e
            innovación de productos para la automatización de espacios.
          </p>

          <p className="text-justify">
            BSMART ofrece sus servicios en la creación de distintas soluciones
            tecnológicas amigables para mejorar la calidad de vida de las
            personas y con el propósito de transformar espacios, generando mayor
            confort, ahorro, bienestar, productividad y seguridad, a través de
            productos de avanzada, con tecnologías especializadas que permiten
            controlar de manera fácil e intuitiva los espacios, equipos y
            sistemas, optimizando la experiencia de los usuarios en su entorno.
          </p>

          <p className="text-justify">
            “BSMART”, es un servicio prestado a través de la sociedad B SMART
            E.U constituida conforme a las leyes de la República de Colombia,
            identificada con NIT 900232815 - 6 según el Registro Mercantil de la
            Cámara de Comercio de Bogotá, domiciliada en la ciudad de Bogotá
            D.C., y que para los efectos de estos TÉRMINOS Y CONDICIONES se
            denominará “BSMART”
          </p>

          <h4>DEFINICIONES:</h4>

          <p className="text-justify">
            USUARIOS y/o CLIENTES: Se entiende por usuario quien utiliza los
            servicios de BSMART y acepta los presentes términos y condiciones.
            También comprende La(s) persona(s) jurídica(s) o natural(es) que
            accedan a la Plataforma para hacer uso de los servicios que se
            encuentran a disposición en esta, clasificados entonces como
            COMPRADORES Y VENDEDORES.
          </p>

          <p className="text-justify">
            PLATAFORMA: Sitio web mediante la cual se prestan los servicios de
            BSMART
          </p>

          <p className="text-justify">
            NOVEDAD: Cualquier solicitud o reclamación por parte de los USUARIOS
          </p>

          <p className="text-justify">
            COOKIES: son pequeños archivos que se instalan en el disco duro de
            la terminal con la que acceda el Usuario, para la consulta por parte
            del Proveedor acerca de la actividad, hábitos y preferencias de
            consumo de los Usuarios.
          </p>

          <p className="text-justify">
            DATOS PERSONALES: cualquier pieza de información vinculada o que
            pueda asociarse a una o varias personas naturales, determinadas o
            determinables.
          </p>

          <p className="text-justify">
            PROPIEDAD INTELECTUAL: cualquier activo intangible susceptible de
            protección por vía de Propiedad Intelectual, incluyendo, pero sin
            limitarse a, signos distintivos, nuevas creaciones, secretos
            empresariales y todas las obras y contenidos amparados por el
            derecho de autor.
          </p>

          <p className="text-justify">
            LAS PARTES:  Corresponde a BSMART, USUARIOS Y/O CLIENTES
          </p>

          <p className="text-justify">
            SOFTWARE: Hace referencia a todo el software disponible, a través de
            este Plataforma o cualquier otro Plataforma o medio proporcionado
            por BSMART incluyendo, sin limitaciones, la información de las
            aplicaciones móviles de BSMART, disponibles para las diferentes
            plataformas móviles.
          </p>

          <p className="text-justify">
            POLÍTICA DE PRIVACIDAD: Significa las políticas de privacidad
            de BSMART las cuales se encuentran debidamente publicadas en la
            Plataforma, en el link{" "}
            <a href="https://BSMART.com/politica_privacidad">
              https://BSMART.com/politica_privacidad
            </a>
          </p>

          <h4>BLUE HOME:</h4>

          <p className="text-justify">
            DESCRIPCIÓN DE LOS PRODUCTOS: Los productos que ofrece BSMART son
            los siguientes: BLUE HOME: Aspectos Generales BLUE HOME es un
            sistema de AUTOMATIZACIÓN DE ESPACIOS RESIDENCIALES cuyo propósito
            es brindar una serie de soluciones para el hogar, controlando todos
            y cada uno de los espacios del hogar a través de una APP destinada
            para tal efecto. BLUE OFFICE: BLUE HOME es un sistema de
            AUTOMATIZACIÓN CORPORATIVA cuyo propósito es brindar una serie de
            soluciones para la oficina, controlando todos y cada uno de los
            espacios del hogar a través de una APP destinada para tal efecto.
            DESCRIPCIÓN DE LOS SERVICIOS Los servicios que presta BSMART son los
            siguientes: BLUE BUILDING: Aspectos Generales: BLUE BUILDING
            consiste en un sistema de instalación de CÁMARAS, SENSONES Y
            SISTEMAS DE ACCESO el cual funciona a través de INTELIGENCIA
            ARTIFICIAL y destinado a COPROPIEDADES y UNIDADES RESIDENCIALES cuyo
            objetivo es la instalación de cámaras en cada piso de un edificio o
            zona de tráfico, una exclusa en cada puerta, y sensores en cada zona
            de riesgo con la más reciente tecnología, integrado con una red de
            apoyo e inteligencia artificial de propiedad de BSMART para aumentar
            la seguridad de las áreas. A través de este sistema y con
            reconocimiento facial se podrá alertar de forma temprana si un
            rostro no identificado (sospechoso) o delincuente (rostro denunciado
            previamente) está cerca de una determinada copropiedad. Por medio de
            inteligencia artificial se reconoce el rostro de cada residente o de
            una persona ajena a la copropiedad. Todos los residentes de la
            respectiva copropiedad tienen el control y monitoreo total de la
            copropiedad desde una APP, previamente instalada donde pueden
            activar alarmas, botones de pánico, o autorizar ingreso a sus
            familiares e invitados o reservar parqueaderos de visitantes y zonas
            comunes. BSMART se encarga de instalar todos los equipos en la
            respectiva copropiedad y los entrega en calidad de comodato, para lo
            cual la respectiva copropiedad deberá cancelar una mensualidad por
            el uso del servicio, la cual incluye además de la instalación de
            estos equipos, un sitio web personalizada para el edificio, los
            servicios en la nube SaaS (Software as a Service), el hosting de la
            información capturada por los equipos de hardware otorgados en
            comodato, y el mantenimiento remoto del software y de equipos de
            hardware. BLUEBUILDING reemplaza a los vigilantes y la seguridad
            tradicional, con un sistema tecnológico seguro, confiable y efectivo
            que trabaja 7x24, para mejorar la vida de las personas y ahorrar
            hasta el 70% de la tarifa. La entrega del software se realizará
            mediante mensaje de dato, links, URL, portal de acceso o cualquier
            otro medio idóneo que contenga la información y material intangible
            de programación (Software) a la cuenta de correo electrónico de la
            copropiedad quien tendrá un acceso codificado otorgado por BSMART.
            Para efectos de implementación y uso del software, BSMART entregará
            a título de comodato precario, las soluciones tecnológicas físicas
            (Hardware) que se encuentran determinadas los anexos al contrato
            previamente celebrado entre LAS PARTES Los costos de instalación y
            de obra civil estarán a cargo de la copropiedad, quien responderá
            por los daños o perjuicios que se ocasionen respecto de los bienes
            muebles (hardware) dejados bajo su uso y custodia, según como se
            estipula en los artículos 2220 y siguientes del Código Civil
            Colombiano o sus normas complementarias o sustitutas. Personas
            habilitadas para el uso. Podrán hacer uso de BLUEBUILDING las
            siguientes personas: xxxxx Servicios Complementarios: BLUEBUILDING
            cuenta con los siguientes servicios complementarios: PÓLIZA DE
            HURTO: En alianza con SEGUROS DEL ESTADO S.A, BSMART cuenta con una
            PÓLIZA DE HURTO que respalda cualquier eventualidad. Las condiciones
            de uso e implementación de la referida póliza son definidas por
            SEGUROS DEL ESTADO S.A. BOTON DE PANICO: El sistema de BLUEBUILDING
            permite activar diferentes modos de pánico para alertar a la
            copropiedad, entre los que se encuentran los siguientes: 1. Pánico
            de seguridad para bloquear todas las puertas tanto de entrada como
            de salida para limitar la fuga de algún intruso, activar la alarma a
            la empresa de seguridad, y limitar la entrada de un residente para
            que no se exponga a esta situación. 2. Pánico de emergencia para
            liberar todas las puertas en caso de emergencia médica, terremoto,
            incendio, etc. 3. Botón de alerta ante una actividad sospechosa para
            activar una alarma y dejar un registro sobre los videos de las
            cámaras y luego poder hacer un registro más rápido. La reacción al
            botón de pánico la presta una empresa de seguridad aliada a BSMART,
            con sus protocolos y condiciones descritos en su respectivo
            contrato. INTERNET COMUNAL: La copropiedad puede disponer de una red
            segura para conexión a internet de todo un edificio sin costos
            adicionales. La copropiedad debe contratar un plan de internet para
            todo el edificio con cualquier operador. El plan se calculará según
            la cantidad de unidades habitacionales en la copropiedad. CONTROL DE
            ZONAS COMUNES: Con BLUEBUILDING se podrán reservar los parqueaderos
            de visitantes, el salón comunal, el BBQ, la piscina o cualquier zona
            común, así todos los residentes podrán saber quién lo está ocupando
            en tiempo real. Si la copropiedad lo decide se pueden incluir
            tarifas de reserva para recaudo e información real a la
            administración. CERO COSTOS DE MANTENIMIENTO DE EQUIPOS EN COMODATO:
            Durante el tiempo del contrato todos los equipos son instalados en
            calidad de COMODATO queriendo decir que todo el mantenimiento
            preventivo y correctivo va por cuenta de BLUEBUILDING y obligándose
            la copropiedad a la restitución de los equipos en los casos de
            terminación del contrato. Cada vez que se deba reponer un equipo por
            fallo o mantenimiento, BSMART instalará la más reciente tecnología
            disponible. ASISTENCIA AL HOGAR: La póliza otorgada por SEGUROS DEL
            ESTADO S.A da acceso a diferentes asistencias en caso de accidente o
            un evento imprevisto con servicios adicionales de: Cerrajería,
            Plomería, Electricidad, Vidriería, Jardinería, Gastos por celaduría
            de Emergencia. Gastos de Hotel, Vigilancia, Mudanza y Bodegaje, por
            inhabitabilidad de la vivienda. Secado de alfombra, Reposición de
            televisión y video y Asistencia jurídica telefónica. Lo anterior de
            conformidad con los términos y condiciones descritos por SEGUROS DEL
            ESTADO S.A. Cada solicitud tiene una tarifa adicional de $35.000
            descrita en la póliza de hurto. La tarifa final será la descrita en
            la póliza. PUERTAS CON ESCLUSA: Se limita la circulación de algunas
            zonas críticas para asegurar no quede una puerta abierta, asegurando
            que las personas deban cerrar la primera puerta antes de abrir la
            segunda. RESPALDO MOTORIZADO: BSMART en alianza con una empresa de
            seguridad mantiene monitoreado todo el sistema, en caso de alarma o
            pánico una de las personas de la empresa de seguridad seguirá el
            protocolo para siempre mantener los espacios seguros. CARTELERA /
            VOTACION / CHAT: BLUEBUILDING ofrece una sección en donde la
            administración de la copropiedad podrá publicar las novedades o
            comunicaciones para todos los residentes. Las encuestas o votaciones
            de asamblea permiten llevar un registro histórico de las decisiones
            tomadas. Y con el chat se pueden comunicar entre los vecinos de
            forma fácil y practica en caso de cualquier eventualidad. TARIFA
            ESTANDAR ANUAL: BLUEBUILDING reajusta su tarifa conforme al Índice
            de Precios al Consumidor (IPC). Restricciones de uso del servicio.
            El servicio NO puede ser utilizado por las siguientes personas:
            Xxxxx El servicio NO puede ser utilizado en las siguientes
            situaciones: Xxxx Disponibilidad del servicio. BSMART limita su
            campo de acción a facilitar en calidad de comodato equipos
            tecnológicos que almacenan transacciones, videos o registros de cada
            operación que se hace en el edificio para que sea consultada
            posteriormente. El software de BSMART/BUEBUILDING permite (bajo
            autorización) que el servidor donde se almacena la información haga
            procesos de “cloud computing” y “Machine Learning” por lo que
            responderá en los casos de presentarse fallas técnicas u operativas
            de los equipos en referencia. En relación a los servicios
            complementarios y que son prestados por terceras empresas aliadas a
            BSMART, responderá cada una de ellas en el ámbito de sus
            competencias, por lo que BSMART solo garantiza la continuidad del
            servicio en relación con el funcionamiento continuo de los equipos
            entregados en comodato. Exención de responsabilidad BSMART NO es una
            empresa de seguridad, filial, sucursal, ni similar ni tampoco es
            operador de medios tecnológicos, puesto que él no está calificado
            para prestar ningún tipo de seguridad o control. BSMART limita su
            campo de acción a facilitar en calidad de comodato equipos
            tecnológicos que almacenan transacciones, videos o registros de cada
            operación que se hace en el edificio para que sea consultada
            posteriormente. El software de BSMART/BUEBUILDING permite (bajo
            autorización) que el servidor donde se almacena la información haga
            procesos de “cloud computing” como lo es la Inteligencia Artificial
            o el “Machine Learning” para perfeccionar las respuestas o los
            registros del sistema. El personal de BSMART/BLUEBUILDING es
            exclusivo para labores técnicas de instalación, soporte técnico o
            reparación de equipos. BSMART no es responsable de los Plataformas
            web externos o recursos vinculados o referenciados en el Plataforma.
            BSMART no respalda ni es responsable de ningún contenido,
            publicidad, productos u otros materiales disponibles en dichos
            Plataformas o recursos. Los usuarios aceptan que BSMART no será
            responsable, directa o indirectamente, por cualquier daño o pérdida
            causada por el uso de cualquier contenido, bienes o servicios
            disponibles en Plataformas web o recursos externos a BSMART. .
            RESPONSABILIDADES: BSMART al entregar al USUARIO los equipos y
            recursos tecnológicos en calidad de COMODATO, responderá por las
            fallas o imprevistos que puedan ocasionarse en relación a los
            referidos equipos y responderá en los casos de actualización,
            mantenimiento o reemplazo. En los casos de los servicios prestados a
            través de empresas aliadas, estas responderán bajo el ámbito de sus
            competencias, manteniendo indemne a BSMART por cualquier reclamación
            que no sea relacionada con sus servicios. BSMART hace lo posible
            porque la información que se presenta a USUARIOS y/o CLIENTES sea
            veraz y exacta a fin de otorgar el acceso a sus servicios. BSMART,
            se basa en la confianza, honestidad y buena fe de sus USUARIOS y/o
            CLIENTES quienes garantizarán la autenticidad de todos los datos
            proporcionados y las actualizaciones de estos, siendo responsables
            de todos los daños, que se deriven de cualquier inexactitud en los
            mismos. Sin perjuicio de que exista solidaridad en la
            responsabilidad entre los USUARIOS y BSMART, la empresa tomará las
            acciones legales pertinentes contra los USUARIOS, y no se
            responsabiliza por las consecuencias derivadas, de las acciones o
            reclamos que terceras partes ejecuten, haciendo referencia o
            invocando, ya sea directa o indirectamente, la información incluida
            en la Plataforma. PRIVACIDAD DE LA INFORMACIÓN: Toda la información
            proporcionada por o recopilada por BSMART en relación con los
            servicios se rige mediante la POLÍTICA DE PRIVACIDAD Y
            CONFIDENCIALIDAD DE LA INFORMACIÓN Y DATOS PERSONALES de BSMART. Se
            puede encontrar una copia de la misma en el sitio web
            https://BSMART.com/politica_privacidad que, por la presente, se
            incorpora por referencia en estos TÉRMINOS Y CONDICIONES BSMART
            recomienda la revisión periódica de la POLÍTICA DE PRIVACIDAD Y
            CONFIDENCIALIDAD DE LA INFORMACIÓN Y DATOS PERSONALES detenidamente.
            En concreto, los USUARIOS deben tener en cuenta que BSMART puede
            utilizar la información que recibe o recopila sobre los USUARIOS de
            acuerdo con los términos de su Política de privacidad, BSMART
            respetará la confidencialidad y la seguridad de los datos
            recolectados de los USUARIOS con el fin de (a) proteger la seguridad
            y la integridad de los datos mientras estos se encuentran en los
            sistemas de BSMART y evitar el acceso, uso, modificación o
            divulgación de dichos datos de los sistemas de BSMART de forma
            accidental o sin autorización. TÉRMINOS Y CONDICIONES DE CONTENIDO:
            Los USUARIOS aceptan que todo el material, incluyendo, sin
            limitación, la información, el software, los datos, el texto, los
            gráficos, los elementos de diseño, las imágenes y todo tipo de
            contenido incluido o entregado a través de los servicios o puesto a
            su disposición de otro modo por BSMART, en relación con los
            servicios, se encuentra protegido por los derechos de autor, las
            marcas comerciales, marcas de servicio, secretos comerciales u otras
            leyes y derechos de propiedad intelectual y de otro tipo de
            propiedad. BSMART, puede poseer el contenido de la Plataforma o
            puede poner a disposición parte del contenido de la misma, mediante
            acuerdos con terceros. Salvo que BSMART, lo autorice expresamente
            por escrito o en relación con su uso de la funcionalidad prevista de
            los servicios, los USUARIOS aceptan no reproducir, transmitir,
            modificar, distribuir, copiar, mostrar públicamente, realizar
            públicamente, publicar, adaptar, editar, vender, licenciar, alquilar
            o crear trabajos derivados de cualquier contenido de la Plataforma,
            ni publicar contenido de la misma, en cualquier Plataforma web o en
            un entorno informático en red para cualquier fin. La reproducción,
            copia o distribución para cualquier otro fin, se encuentran
            estrictamente prohibidas sin el permiso previo explícito por escrito
            de BSMART. Solo podrán los USUARIOS usar el contenido de la
            Plataforma para los fines permitidos por estos TÉRMINOS Y
            CONDICIONES y por cualquier ley y normativa aplicables (nacionales y
            extranjeras). Quedan reservados todos los derechos no concedidos
            aquí expresamente. Queda absolutamente prohibido para los, USUARIOS,
            así como para cualquier otra persona: (a) Realizar actividades de
            ingeniería inversa, scrapping, desmonte, descompile o intente
            revelar el código fuente o la estructura, arquitectura u
            organización de todas o alguna de las partes de los servicios (b)
            rente, alquile, revenda, distribuya o utilice los servicios para
            fines comerciales, sin autorización de sus titulares. (c) haga parte
            en cualquier actividad o tarea que interfiera o interrumpa los
            servicios. MODIFICACIONES, SUSPENSIÓN Y FINALIZACIÓN DEL SERVICIO:
            BSMART se reserva el derecho de, en cualquier momento, modificar,
            suspender o suprimir, temporal o permanentemente, los servicios (o
            cualquier parte relacionada) por cualquier motivo o sin motivo
            alguno, con o sin aviso. Los USUARIOS, aceptan que BSMART no se
            considerará responsable, por ninguna modificación, suspensión o
            supresión de los servicios. BSMART, a su sola discreción, puede
            cancelar su derecho de usar los servicios, en cualquier momento y
            por cualquier motivo incluyendo, sin limitación, la falta de uso, el
            incumplimiento del pago oportuno de cualquier tasa u otros fondos
            adeudados a BSMART, o si se cree que ha infringido o actuado de
            forma incoherente con la letra o espíritu de estos TÉRMINOS Y
            CONDICIONES o cuando se haya verificado la violación de alguna
            disposición legal, o cuando se compruebe que se han utilizado los
            servicios de BSMART para fines ilícitos. INDEMNIDAD: Los USUARIOS
            aceptan mantener indemne y libre de responsabilidades a BSMART, y a
            cada uno de sus oficiales, directores, agentes, socios de marca
            compartida, otros socios y empleados respectivos de todos los daños,
            ya sean directos o indirectos, derivados o de otro tipo, pérdidas,
            responsabilidades, costes y gastos (incluidas, sin limitación, las
            tasas de contabilidad y abogados razonables), que resulten de
            cualquier reclamación, demanda, acción, procedimiento (ya sea ante
            un árbitro, tribunal, mediador u otro) o investigación realizada por
            cualquier tercera parte (cada una es una “Reclamación”) debida a o
            que surja de su CONTENIDO, su infracción de estos TÉRMINOS Y
            CONDICIONES; y/o su infracción de cualquier derecho de otra persona.
            USO DE MARCAS COMERCIALES: Las marcas comerciales, marcas de
            servicio y logotipos de BSMART, denominadas “Marcas Comerciales de
            BSMART”, utilizadas y mostradas en relación con los Servicios son
            marcas comerciales o marcas de servicio de BSMART registradas y no
            registradas. Otros nombres de empresas, productos y servicios
            utilizados en relación con los Servicios pueden ser marcas
            comerciales o de servicio propiedad de terceros, denominadas “Marcas
            Comerciales de Terceros” y, junto con las Marcas Comerciales de
            BSMART se denominarán “Marcas Comerciales”. La oferta de los
            Servicios no debe constituir la concesión, por implicación,
            impedimento o de otro modo, de licencia o derecho algunos para usar
            cualquier Marca Comercial mostrada en relación con los Servicios sin
            el consentimiento previo por escrito de BSMART específico para cada
            uso. Las Marcas Comerciales no pueden utilizarse para menospreciar a
            BSMART, a cualquier tercera parte o a los productos o servicios de
            BSMART o un tercero, o de cualquier forma (a juicio exclusivo de
            BSMART) que pueda dañar cualquier buena fe en las Marcas
            Comerciales. El uso de cualquier Marca Comercial como parte de un
            enlace a o desde cualquier Plataforma está prohibido salvo que
            BSMART apruebe el establecimiento de dicho enlace mediante
            consentimiento previo por escrito para cada enlace. Toda la buena fe
            generada a partir del uso de cualquier Marca Comercial de BSMART
            redundará en beneficio de BSMART. PROPIEDAD INTELECTUAL: BSMART es
            el titular de todos los derechos que en virtud de las leyes de
            Propiedad Intelectual se le reconocen al creador de una obra. El
            contenido como contrato, texto, imágenes, logos, botones, iconos,
            marcas comerciales y otros, son propiedad intelectual de BSMART (a
            excepción de material propiedad de terceros previamente autorizados
            y de Plataformas web de imágenes de contenido gratuito y libres de
            derechos de autor) y están protegidos bajo los derechos de autor,
            marcas y en general derechos de propiedad intelectual. Todo el
            material informático, gráfico, publicitario, fotográfico, de
            multimedia, audiovisual y de diseño, así como todos los contenidos,
            textos y bases de datos puestos a su disposición en esta plataforma
            están protegidos por derechos de autor y/o propiedad industrial cuyo
            titular es BSMART, o en algunos casos, de terceros que han
            autorizado su uso o explotación. Igualmente, el uso en la Plataforma
            de algunos materiales de propiedad de terceros se encuentra
            expresamente autorizado por la ley o por dichos terceros. Todos los
            contenidos en la Plataforma están protegidos por las normas sobre
            derecho de autor y por todas las normas nacionales e internacionales
            que le sean aplicables. LEGISLACIÓN APLICABLE: Estos TÉRMINOS Y
            CONDICIONES serán interpretados y ejecutados exclusivamente de
            acuerdo a las leyes de la República de Colombia. MODIFICACIONES
            BSMART se reserva el derecho de modificar estos TERMINOS Y
            CONDICIONES u otras políticas del servicio en cualquier momento, así
            que se solicita a los usuarios revisar las políticas con frecuencia.
            En todo lo convenido y en lo que no se encuentre expresamente
            previsto, éste contrato se regirá por las leyes vigentes de la
            República de Colombia. Ninguna sección de estos TÉRMINOS Y
            CONDICIONES podrá ser modificada, unilateralmente por los USUARIOS.
            APLICABILIDAD: Si cualquier sección o parte de estos TÉRMINOS Y
            CONDICIONES, no se puede aplicar o resulta inválida, en su totalidad
            o en parte, bajo cualquier ley, o sea sentenciada como tal por
            decisión judicial, dicha parte será interpretada de conformidad con
            la ley aplicable y su falta de aplicabilidad o invalidez no hará que
            estos TÉRMINOS Y CONDICIONES en general y las disposiciones
            remanentes o porciones de ellas sean inaplicables o inválidas o
            ineficaces en su totalidad y, en tal evento, dichas disposiciones
            serán cambiadas e interpretadas de tal manera que se logren de la
            mejor manera posible los objetivos de tales disposiciones no
            aplicables o inválidas, dentro de los límites de la ley aplicable o
            las decisiones aplicables de la por sentencias judiciales.
            INTEGRIDAD Estos TÉRMINOS Y CONDICIONES, constituyen los únicos
            términos entre BSMART y el usuario. La aceptación de estos TÉRMINOS
            Y CONDICIONES, derogan cualquier acuerdo, pacto, declaración,
            entendimiento y garantía anterior o contemporánea con respecto a
            esta Plataforma, el Contenido, la información contenida en esta
            Plataforma, y el objeto de estos TÉRMINOS Y CONDICIONES. En caso de
            cualquier conflicto entre estos TÉRMINOS Y CONDICIONES y cualquier
            acuerdo o entendimiento verbal, escrito o previo, prevalecerán estos
            TÉRMINOS Y CONDICIONES. NO RENUNCIA SOBRE EJECUCIÓN DEL ACUERDO La
            no aplicación de BSMART de alguna de las condiciones, términos y
            derechos incluidos en estos TÉRMINOS Y CONDICIONES no será
            interpretada como desistimiento o renuncia del derecho de BSMART en
            lo sucesivo de hacer cumplir o ejecutar dichas disposiciones. ÚLTIMA
            ACTUALIZACIÓN Estos TERMINOS Y CONDICIONES fueron actualizados por
            última vez el 14 de Julio de 2020 Le recordamos que cuando el
            usuario accede y hace uso de la Plataforma, incluyendo todos y
            cualquiera de los Plataformas web de BSMART, entenderemos que el
            usuario se obliga libremente al cumplimiento de estos TÉRMINOS Y
            CONDICIONES. RESOLUCIÓN DE CONTROVERSIAS En el caso de que surja una
            disputa entre BSMART y un USUARIO se resolverá en primera instancia
            mediante ARREGLO DIRECTO entre las mismas partes involucradas con el
            fin de dar solución a la controversia suscitada. Si la controversia
            suscitada no se puede resolver de manera directa entre las partes,
            luego de un término prudencial el cual no deberá sobrepasar los
            quince (15) días hábiles contados desde el momento en que la parte
            afectada puso en conocimiento de la otra la respectiva controversia,
            se resolverá mediante la intervención de un Centro de Conciliación o
            un Tribunal de Arbitramento a elección de las partes y en caso de
            persistir las diferencias entre estas. En caso de recurrir a la
            Intervención de un Tribunal de Arbitramento, este estará integrado
            por tres (3) árbitros, designados por la Cámara de Comercio de
            Bogotá. El Tribunal decidirá en derecho. En caso de que la
            controversia sea de carácter técnico, la Cámara de Comercio
            designará un perito especializado en el tema. El término para que el
            Tribunal se pronuncie de fondo será de tres (3) meses a partir de su
            constitución. Los costos que se originen en la constitución y
            funcionamiento del Tribunal serán sufragados por la parte
            solicitante. En la medida en que BSMART intenta resolver una
            disputa, BSMART lo hará de buena fe basándose únicamente en las
            políticas de BSMART. ACEPTACIÓN DE LOS TÉRMINOS Y CONDICIONES DEL
            SERVICIO El Usuario acepta expresamente los Términos y Condiciones,
            siendo condición esencial para la utilización de la Plataforma. En
            el evento en que NO se encuentre conforme con estos Términos y
            Condiciones, NO podrá hacer uso de nuestros servicios. BSMART podrá
            modificar los presentes términos y condiciones, avisando a los
            usuarios de la Plataforma o mediante la difusión de las
            modificaciones por algún medio electrónico, como la Plataforma mismo
            o correo electrónico, lo cual se entenderá aceptado con su
            recepción. BSMART se reserva el derecho de modificar o sustituir, a
            su criterio exclusivo, estos TÉRMINOS Y CONDICIONES en cualquier
            momento. Los usuarios serán responsables de verificar y comprobar
            periódicamente si estos TÉRMINOS Y CONDICIONES han sido modificados.
            Al continuar utilizando los Servicios después de realizarse
            cualquier modificación sobre los TÉRMINOS Y CONDICIONES, están
            aceptando los cambios realizados sobre los TÉRMINOS Y CONDICIONES.
            NOTIFICACIONES: BSMART señala medio para atender notificaciones al
            correo: info@bsmart.co Las notificaciones y los recursos sobre los
            Servicios se deben enviar dirigidos a nfo@bsmart.co (57) (1) xxxxxxx
            Versión N° 1 TERMINOS Y CONDICIONES “BSMART” Julio de 2020
          </p>

          <p className="text-justify"></p>

          <p className="text-justify"></p>
        </div>
      </Modal>
    );
  }
}

TerminosYCondiciones.propTypes = {
  onModalClosed: PropTypes.func,
};

export default TerminosYCondiciones;
