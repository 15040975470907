import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MenuInferior from '../components/menuInferior.jsx';
import Menu from '../components/menu.js';
import '../assets/css/llamada.css';
import user01 from '../assets/img/user01.jpg';

export class Llamada extends Component {
  render() {
    return (
      <div className="App llamada">
        <div className="container">
          <div className="videollamada">
            <h5>Video title</h5>
            <button type="button" className="close">
                <span aria-hidden="true">&times;</span>
            </button>
            {/* <video id={this.props.id} ref={}/> */}
          </div>
          <div className="btn-llamada d-flex list-group" id="list-tab" role="tablist">
              <a className="close-up" id="btn-close" data-toggle="list" role="tab" aria-controls="close" href="#nada"></a>
              <a className="botton btn-primary" id="btn-conocido" data-toggle="list" href="#conocido" role="tab" aria-controls="home">
                Conocido
              </a>
              <a className="botton btn-secondary" id="btn-desconocido" data-toggle="list" href="#desconocido" role="tab" aria-controls="profile">
                Desconocido
              </a>
          </div>
          <div className="opc-llamada tab-content list-group" id="nav-tabContent">
            <div className="tab-pane fade" id="conocido" role="tabpanel" aria-labelledby="btn-conocido">
                <div className="buscador">
                    <input type="text" placeholder="buscar"/>
                    <input type="submit" />
                </div>
                <ul>
                    <li>
                      <a>
                        <img src={user01}/>
                        <p>Nombre completo del conocido</p>
                      </a>
                    </li>
                    <li>
                      <a>
                        <img src={user01}/>
                        <p>Nombre completo del conocido</p>
                      </a>
                    </li>
                    <li>
                      <a>
                        <img src={user01}/>
                        <p>Nombre completo del conocido</p>
                      </a>
                    </li>
                </ul>
                <div id="btn-desconocido" className="btnMas" data-toggle="list" href="#desconocido" role="tab" aria-controls="profile">+</div>
            </div>
            <div className="tab-pane fade" id="desconocido" role="tabpanel" aria-labelledby="btn-desconocido">
              <form>
                <div className="foto-perfil mx-auto">
                    <div>
                      <img src={this.props.userimage}/>
                      <input type="file" name="fileupload" id="fileupload" accept="image/*"/>
                    </div>
                </div>
                <input type="text" placeholder="Nombre conocido" name="nuevo-conocido" id="conocidonombre"/>
                <input type="text" placeholder="Numero documento" name="conocidodocumento" id="conocidodocumento"/>
                <input type="submit" value="Agregar" className="botton btn-primary"/>
              </form>
            </div>
            <div className="fade" id="nada" role="tabpanel" aria-labelledby="btn-close"></div>
          </div>

        </div>
        <MenuInferior/>
        <Menu/>
      </div>
    );
  }
}

Llamada.propTypes = {
  userimage: PropTypes.string
}

export default Llamada;
