import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { FormInput, FormButton } from '../../components/campo.js';
import Logo from '../../logo';

import { getVisitCode, updateVisitCode } from '../../utils/services/buildingService';
import { showErrorAlert, showSuccessAlert } from '../../utils/alerts.js';

export class buildingDashboardVisitsCode extends Component {

  constructor(props) {
    super(props);
    this.state = {
      code: '',
      building: {
        id: '',
      },
      visit: {
        id: null,
        code: '',
        user: {
          attachments: [],
          cedula: '',
          name: ''
        }
      },
      token: '',
      error: null
    }
  }

  componentDidMount = async () => {
    try {
      if (localStorage.getItem('authToken')) {
        this.setState({
          building: {
            id: localStorage.getItem('buildingId')
          },
          token: localStorage.getItem('authToken'),
        })
      }
    } catch(error) {
    console.log(`buildingDashboardVisitsCode -> componentDidMount -> error`, error)
    }
  }

  render() {
    const validCode = this.state.visit.id;
    const {
      cedula,
      attachments
    } = this.state.visit.user;
    let photo = '';
    if (Array.isArray(attachments) && attachments.length > 0) {
      photo = attachments[0].path
    }
    return (
      <div className="App bg-blue">
        <div className="container-dashboard">
          <Logo className="App-logo w-75" />
          <div>
            <div>
              Dashboard veriricacion código de acceso.
            </div>
            {(validCode != null) &&
              <div className="skew-input input-shape w-100 mx-auto">
                <FormButton type="button" value="AuthorizeAccess" textbutton="Autorizar acceso" className="btn w-75 mx-auto" onClick={this.onAuthorizationButton} />
              </div>
            }
            <div className="w-100 mx-auto">
              Cedula: {cedula}
            </div>
            <div className="w-100 mx-auto">
              <img src={photo}></img>
            </div>
            <div className="skew-input input-shape w-100 mx-auto">
              <FormInput
                id="form-code"
                type="text"
                name="code"
                placeholder="abc"
                value={this.state.code}
                onChange={this.handleInputChange}
                required />
            </div>
            <FormButton type="button" value="Verificar" textbutton="Verificar" className="btn w-75 mx-auto" onClick={this.onValidateButton}/>
            <FormButton type="button" value="Limpiar" textbutton="Limpiar" className="btn w-75 mx-auto" onClick={this.onCleanButton}/>
          </div>
        </div>
      </div>
    );
  }

  handleInputChange = async (event) => {
    this.setState({
      code: event.target.value
    })
  }

  onValidateButton = async () => {
    try {
      const {
        code,
        building: {
          id
        },
        token
      } = this.state;
      const {
        data: {
          visit
        }
      } = await getVisitCode(id, code, token)
      console.log(`buildingDashboardVisitsCode -> onValidateButton -> verificationCode`, visit)
      this.setState({
        visit: {
          id: visit.id,
          code: visit.code,
          user: visit.user
        },
      })
    } catch(error) {
      console.log(`buildingDashboardVisitsCode -> onValidateButton -> error`, error)
      showErrorAlert('ups', 'Código no autorizado !!!')
      this.onCleanButton()
    }
  }

  onCleanButton = async () => {
    this.setState({
      code: '',
      visit: {
        id: null,
        code: '',
        user: {
          attachments: [],
          cedula: '',
          name: ''
        }
      },
    });
  }

  onAuthorizationButton = async () => {
    try {
      const {
        code,
        building: {
          id
        },
        token
      } = this.state
      const ok = await updateVisitCode(id, code, token)
      console.log(`buildingDashboardVisitsCode -> onAuthorizationButton -> ok`, ok)
      showSuccessAlert('Código valido', 'Acceso Permitido')
      this.onCleanButton()
    } catch (error) {
      console.log(`buildingDashboardVisitsCode -> onAuthorizationButton -> error`, error)
      showErrorAlert('¡lo sentimos!','Código no autorizado')
      this.onCleanButton()
    }
  }

}

export default buildingDashboardVisitsCode;
