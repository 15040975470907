import React from "react";
import { useSocket } from "../contexts/socketContext";
import PropTypes from "prop-types";

const Layout = ({ children }) => {
    const { isConnected } = useSocket();

    return (
        <div className="bg-blue">
            <main>{children}</main>
            {!isConnected && (
                <div className="server-status">
                    Servidor desconectado <span className="material-icons">wifi_tethering_error_rounded</span>
                </div>
            )}
        </div>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
