import { url_base } from "../../config/url";
import axios from "axios";
/** Services */
import { getApartmentAccess } from "./apartmentService";
import { getOwnerAccess } from "./buildingService";

const routes = {
  cameras: {
    label: "Cámaras",
    path: "/camaras",
  },
  access: {
    label: "Acceso",
    path: "/acceso-visitante",
  },
  profile: {
    label: "Perfil",
    path: "/profile",
  },
  adminDashboard: {
    label: "Dashboard de Administración",
    path: "/admin-dashboard",
  },  
  cardManager:{
    label: "Administrador de tarjetas",
    path: "/admin-dashboard/card-manager",
  },
  verifyCode: {
    label: "Verificar Código ingreso",
    path: "/building/visits/code/confirm",
  },
  requestCode: {
    label: "Solicitar código de ingreso",
    path: "/visits/code",
  },
  ownerRequests: {
    label: "Solicitudes de Propietarios",
    path: "/building/dashboard/apartments/requests/owner",
  },
  adminBuildingApartment: {
    label: "Administrar Apartamento",
    path: "/admin-dashboard/admin-building-apartment",
  },
  adminBuildings: {
    label: "Administrar Edificios",
    path: "/admin-dashboard/admin-buildings",
  },
  employeeRequests: {
    label: "Solicitudes de Usuarios",
    path: "/building/dashboard/apartments/requests/employee",
  },
};

export function getCurrentUser(token) {
  return axios({
    method: "get",
    url: `${url_base}/api/user/auth/currentUser`,
    headers: {
      Authorization: `JWT ${token}`,
      "Content-Type": "application/json",
    },
  });
}

async function hasAccessRequests(type, user) {
  try {
    if (!user || !user.apartment || !user.token) {
      return false;
    }
    const options = {
      owners: async () => getOwnerAccess(user.apartment.id, user.token),
      employees: async () => getApartmentAccess(user.apartment.id, user.token),
      default: async () => null,
    };

    const {
      data: { accessRequests },
    } = await (options[type] || options.default)();

    return accessRequests && accessRequests.length > 0;
  } catch (error) {
    console.error(`authService -> hasAccessRequests`, error);
    return false;
  }
}

export async function getLoggedPath(data) {
  const user = mapUser(data);
  /** SUPER_USER */
  if (user.userType && user.userType !== "USER") {
    return routes.adminDashboard.path;
  }

  const options = {
    RESIDENTE_LIDER: async (user) => {
      return (await hasAccessRequests("employees", user))
        ? routes.employeeRequests.path
        : routes.cameras.path;
    },
    RESIDENTE: async () => routes.cameras.path,
    RESIDENTE_TEMPORAL: async () => routes.cameras.path,
    VISITANTE_CONOCIDO: async () => routes.requestCode.path,
    VISITANTE_DESCONOCIDO: async () => "/",
    ADMINISTRADOR: async (user) => {
      if (await hasAccessRequests("employees", user)) {
        return routes.employeeRequests.path;
      }
      if (await hasAccessRequests("owners", user)) {
        return routes.ownerRequests.path;
      }
      return routes.profile.path;
    },
    CONSERJE: async () => routes.profile.path,
    ACCIONISTA: async () => routes.profile.path,
    PROPIETARIO: async () => routes.cameras.path,
    EMPLEADO_ADMINISTRATIVO: async () => routes.profile.path,
    SERVICIOS: async () => routes.profile.path,
    POLICIA: async () => routes.profile.path,
    PORTERIA: async () => routes.access.path,
    default: async () => routes.profile.path,
  };

  return await (options[user.role] || options.default)(user);
}

const mapUser = (data) => {
  let currentUser = { ...data.user };

  if (Array.isArray(data.spaces) && data.spaces.length > 0) {
    const { role, building, apartment } = data.spaces[0];
    currentUser = {
      ...currentUser,
      role: role.name,
      apartment,
      building,
    };
  }
  return currentUser;
};

export function getMenuOptions(data) {
  const user = mapUser(data);
  const {
    profile,
    cardAssignation,
    verifyCode,
    ownerRequests,
    employeeRequests,
    requestCode,
  } = routes;

  /** SUPER_USER */
  if (user.userType && user.userType !== "USER") {
    return [profile, cardAssignation, verifyCode];
  }

  const options = {
    RESIDENTE_LIDER: [profile, employeeRequests],
    ADMINISTRADOR: [profile, employeeRequests, ownerRequests],
    PORTERIA: [profile, verifyCode],
    VISITANTE_CONOCIDO: [profile, requestCode],
    default: [profile],
  };

  return options[user.role] || options.default;
}
