import { url_base } from '../../config/url'
import { post, get } from 'axios'

export function registerApartmentOwner (apartmentId, data) {
  return post(`${url_base}/api/apartments/${apartmentId}/owner`, data, {
    headers: {
      'content-type': 'multipart/form-data'
    }
  })
}

export function registerApartmentUser (apartmentId, data) {
  return post(`${url_base}/api/apartments/${apartmentId}/users`, data, {
    headers: {
      'content-type': 'multipart/form-data'
    }
  })
}

export function grantApartmentAccess (apartmentId, accessId, token, data) {
  return post(
    `${url_base}/api/apartments/${apartmentId}/access/${accessId}`,
    data,
    {
      headers: {
        Authorization: 'JWT ' + token,
        'Content-Type': 'application/json'
      }
    }
  )
}

export function getApartmentAccess (apartmentId, token) {
  return get(`${url_base}/api/apartments/${apartmentId}/access/`, {
    headers: {
      Authorization: 'JWT ' + token,
      'Content-Type': 'application/json'
    }
  })
}
