export const CONSTANS_DOCUMENT_TYPE = {
  CEDULA_CIUDADANIA: 'Cédula de ciudadanía',
  CEDULA_EXTRANJERIA: 'Cédula de extranjería',
  PASAPORTE: 'Pasaporte',
  TARJETA_IDENTIDAD: 'Tarjeta de identidad',
  REGISTRO_CIVIL: 'Registro civil',
  CARNE_DIPLOMATICO: 'Carné diplomático',
  SALVO_CONDUCTO_PERMANENCIA: 'Salvoconducto de permanencia',
  PERMISO_ESPECIAL_PERMANENCIA: 'Permiso especial de permanencia'
}

export const EVENT_TYPE = {
  OPEN_DOOR: "OPEN_DOOR",
  CLOSE_DOOR: "CLOSE_DOOR",
  LONG_OPEN_DOOR: "LONG_OPEN_DOOR",
  FORCED_DOOR: "FORCED_DOOR",
  INTRUSION: "INTRUSION",
  REMOTE_PANIC: "REMOTE_PANIC",
  EMERGENCY: "EMERGENCY",
  BROKEN_SLAVE: "BROKEN_SLAVE",
  ELECTRONIC_FAILURE: "ELECTRONIC_FAILURE",
  ELECTRONIC_OPEN_MASTER: "ELECTRONIC_OPEN_MASTER",
  ELECTRONIC_OPEN_SLAVE: "ELECTRONIC_OPEN_SLAVE",
  IR_MOVE: "IR_MOVE",
  GLASS_BROKEN: "GLASS_BROKEN",
  BARRIER_DETECTED: "BARRIER_DETECTED",
  BARRIER_CUTTED: "BARRIER_CUTTED",
  GAS: "GAS",
  CO2: "CO2",
  FLOOD: "FLOOD",
  ELECTRICITY: "ELECTRICITY",
  WATER_LEVEL: "WATER_LEVEL",
  FIRE: "FIRE",
  SMOKE: "SMOKE",
  EARTHQUAKE: "EARTHQUAKE",
  MUTE: "MUTE"
}
