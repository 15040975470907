import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ImageLoader from '../../../../components/ImageLoader';
import { FormButton } from '../../../../components/campo';

import { setUserAsValidated } from '../../../../utils/services/userService';
import { showErrorAlert, showSuccessAlert } from '../../../../utils/alerts';
import { getErrorMessage } from './utils';

const UserValidator = ({ cedula, onUserValidated }) => {
  const [preview, setPreview] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [registerFormData, setRegisterFormData] = useState({ cedula });

  const updateRegisterFormData = (name, value) => {
    setRegisterFormData((currentFormData) => ({
      ...currentFormData,
      [name]: value,
    }));
  };

  const handleImageChanged = (name, file) => {
    const reader = new FileReader();

    updateRegisterFormData(name, file);
    reader.onloadend = () => {
      const imageObject = { [name]: reader.result };
      setPreview((currentPreview) => ({ ...currentPreview, ...imageObject }));
    };
    reader.readAsDataURL(file);
  };

  const isValidForm = () => {
    const requiredFields = ['photo', 'cedulaFrontal', 'cedulaPosterior'];
    for (let field of requiredFields) {
      if (!registerFormData[field]) {
        showErrorAlert('ups', `Por favor cargue la imagen "${field}" para continuar.`);
        return false;
      }
    }
    return true;
  };

  const submitValidateUser = async () => {
    try {
      setIsSaving(true);

      if (!isValidForm()) {
        setIsSaving(false);
        return;
      }

      const dataForm = new FormData();

      for (let key in registerFormData) {
        dataForm.set(key, registerFormData[key]);
      }

      await setUserAsValidated(dataForm);
      showSuccessAlert('¡Excelente!', 'Usuario validado correctamente.');
      onUserValidated();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      showErrorAlert('Ups', errorMessage);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div>
      <div className="read-card-container">
        <ImageLoader
          label="Foto Rostro"
          previewImg={preview.photo}
          onImageLoaded={(file) => {
            handleImageChanged('photo', file);
          }}
        />

        <ImageLoader
          label="Foto Cédula Frontal"
          previewImg={preview.cedulaFrontal}
          onImageLoaded={(file) => {
            handleImageChanged('cedulaFrontal', file);
          }}
        />

        <ImageLoader
          label="Foto Cédula Posterior"
          previewImg={preview.cedulaPosterior}
          onImageLoaded={(file) => {
            handleImageChanged('cedulaPosterior', file);
          }}
        />

        <div className="w-100 mx-auto d-flex justify-content-center submit-button-container">
          <FormButton
            type="button"
            onClick={submitValidateUser}
            value="Validar Usuario"
            textbutton="Validar Usuario"
            className="btn w-75 mx-auto admin-button"
            isLoading={isSaving}
            disabled={!cedula || isSaving}
          />
        </div>
      </div>
    </div>
  );
};

UserValidator.propTypes = {
  cedula: PropTypes.number,
  onUserValidated: PropTypes.func,
};

export { UserValidator };
