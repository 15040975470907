import React, { Component } from "react";
import PropTypes from "prop-types";
/** Components */
import Loader from "../../components/loader";
import { LogoPeque } from "../../logo";
import { FormInput, FormButton } from "../../components/campo.js";
import TerminosYCondiciones from "../../components/terminosYCondiciones";
/** Services */
import {
  getBuildings,
  getBuildingTowers,
} from "../../utils/services/buildingService";
import { getTowerFloors } from "../../utils/services/towerService";
import { getFloorApartmentsService } from "../../utils/services/floorService";
import { registerApartmentOwner } from "../../utils/services/apartmentService";
import { validateMandatoryFields } from "../../utils/validator";
import { CONSTANS_DOCUMENT_TYPE } from "../../utils/enums";
import { showErrorAlert, showErrorList } from "../../utils/alerts";
import { getErrorsList } from "../../utils/errors";

export class FormApartmentOwnerRegister extends Component {
  constructor(props) {
    super(props);

    this.state = {
      owner: {
        name: "",
        cedula: "",
        documentType: "CEDULA_CIUDADANIA",
        password: "",
        passwordConfirmation: "",
        phone: "",
        photo: null,
        email: "",
        buildingId: "",
        towerId: "",
        floorId: "",
        apartmentId: "",
        acceptTerms: false,
      },
      previewPhoto: null,
      formData: null,
      errors: {},
      error: null,
      towers: [],
      floors: [],
      apartments: [],
      loading: false,
      passwordConfirmation: "",
      documentTypes: CONSTANS_DOCUMENT_TYPE,
      showTermsModal: false,
    };
  }

  async componentDidMount() {
    try {
      const buildings = await getBuildings();
      let buildingId = "";
      let towers = [];
      if (buildings && buildings.length === 1) {
        buildingId = buildings[0].id;
        const result = await getBuildingTowers(buildingId);
        towers = result.data;
      }
      const formData = new FormData();
      this.setState({
        formData,
        owner: {
          ...this.state.owner,
          buildingId,
        },
        buildings,
        towers,
      });
    } catch (error) {
      console.log(`componentDidMount -> error`, error);
    }
  }

  handleCloseTermsModal() {
    this.setState({
      showTermsModal: false,
    });
  }

  render() {
    const {
      previewPhoto,
      errors,
      owner,
      error,
      towers,
      floors,
      apartments,
      buildings,
      loading,
      passwordConfirmation,
      showTermsModal,
    } = this.state;
    return (
      <div className="App bg-blue">
        <div className="container">
          <a onClick={this.goBack} className={"btn-back"} />
          <LogoPeque />
          <form
            onSubmit={this.handleSubmit}
            className="row d-flex w-75 mx-auto justify-content-between"
          >
            <div className="foto-user mx-auto">
              <div>
                {previewPhoto && (
                  <img
                    src={previewPhoto}
                    style={{ borderRadius: "50%" }}
                    alt="Imagen usuario"
                    width="110px"
                    height="110px"
                  />
                )}
              </div>
              <input
                type="file"
                name="photo"
                id="photo"
                accept="image/*"
                onChange={this.handleImage}
              />
            </div>
            {errors.previewPhoto && (
              <div className="error-form-image-container">
                <span className="error-image">* Debes agregar la foto</span>
              </div>
            )}
            <div
              style={{
                fontSize: "1.3em",
                margin: "1em",
                color: "#FFFFFF",
              }}
              className="w-100 mx-auto"
            >
              Registro Usuario Propietario
            </div>
            <div className="skew-input input-shape w-100 mx-auto select">
              <select
                value={owner.buildingId}
                name="buildingId"
                onChange={this.handleBuildingChange}
                id="buildingId"
              >
                <option key="default_building" value="">
                  Selecciona un edificio
                </option>
                {buildings &&
                  buildings
                    .sort((buildingA, buildingB) => buildingA.name.localeCompare(buildingB.name))
                    .map((building) => (
                      <option key={building.id} value={building.id}>
                        {building.name}
                      </option>
                    ))}
              </select>
            </div>
            {errors.buildingId && (
              <div className="error-form-container">
                <span className="error-text">
                  * Debes seleccionar un edificio
                </span>
              </div>
            )}
            <div className="skew-input input-shape-2 w-100 mx-auto select">
              <select
                value={owner.documentType}
                name="documentType"
                onChange={this.handleInputChange}
                id="documentType"
                required
              >
                {Object.keys(this.state.documentTypes)
                  .sort((documentA, documentB) =>
                    this.state.documentTypes[documentA].localeCompare(this.state.documentTypes[documentB])
                  )
                  .map(
                    (documentTypeKey) => (
                      <option key={documentTypeKey} value={documentTypeKey}>
                        {this.state.documentTypes[documentTypeKey]}
                      </option>
                    )
                  )}
              </select>
            </div>
            <div className="skew-input w-100 mx-auto">
              <FormInput
                value={owner.cedula || ""}
                onChange={this.handleInputChange}
                name="cedula"
                id="cedula"
                type="number"
                placeholder="# documento propietario"
                required
              />
            </div>
            {errors.cedula && (
              <div className="error-form-container">
                <span className="error-text">
                  * Debes diligenciar la cédula
                </span>
              </div>
            )}
            <div className="skew-input w-100 mx-auto">
              <FormInput
                value={owner.name || ""}
                onChange={this.handleInputChange}
                name="name"
                id="name"
                type="text"
                placeholder="Nombre del propietario"
                required
              />
            </div>
            {errors.name && (
              <div className="error-form-container">
                <span className="error-text">
                  * Debes diligenciar el nombre
                </span>
              </div>
            )}
            <div className="skew-input w-100 mx-auto">
              <FormInput
                value={owner.phone || ""}
                onChange={this.handleInputChange}
                name="phone"
                id="phone"
                type="number"
                placeholder="Teléfono del propietario"
                required
              />
            </div>
            {errors.phone && (
              <div className="error-form-container">
                <span className="error-text">
                  * Debes diligenciar el teléfono
                </span>
              </div>
            )}
            <div className="skew-input w-100 mx-auto">
              <FormInput
                value={owner.email || ""}
                onChange={this.handleInputChange}
                name="email"
                id="email"
                type="text"
                placeholder="Email del propietario"
                required
              />
            </div>
            {errors.email && (
              <div className="error-form-container">
                <span className="error-text">* Debes diligenciar el email</span>
              </div>
            )}

            <div className="skew-input select w-100">
              <select
                value={owner.towerId}
                name="towerId"
                onChange={this.handleTowerChange}
                id="towerId"
                required
              >
                <option defaultValue="0">Seleccione Torre</option>
                {towers &&
                  towers
                    .sort((towerA, towerB) => towerA.name.localeCompare(towerB.name))
                    .map((tower) => (
                      <option key={tower.id} value={tower.id}>
                        {tower.name}
                      </option>
                    ))}
              </select>
            </div>
            {errors.towerId && (
              <div className="error-form-container">
                <span className="error-text">* Debes seleccionar la torre</span>
              </div>
            )}

            <div className="skew-input select w-100">
              <select
                value={owner.floorId}
                name="floorId"
                onChange={this.handleFloorChange}
                id="floorId"
                required
              >
                <option defaultValue="0">Seleccione Piso</option>
                {floors
                  .sort((floorA, floorB) => floorA.name.localeCompare(floorB.name))
                  .map((floor) => (
                    <option key={floor.id} value={floor.id}>
                      {floor.name}
                    </option>
                  ))}
              </select>
            </div>
            {errors.floorId && (
              <div className="error-form-container">
                <span className="error-text">* Debes seleccionar el piso</span>
              </div>
            )}

            <div className="skew-input select w-100">
              <select
                value={owner.apartmentId}
                name="apartmentId"
                onChange={this.handleApartmentChange}
                id="apartmentId"
                required
              >
                <option defaultValue="0">Seleccione Apartamento</option>
                {apartments
                  .sort((apartmentA, apartmentB) => apartmentA.name.localeCompare(apartmentB.name))
                  .map((apartment) => (
                    <option key={apartment.id} value={apartment.id}>
                      {apartment.name}
                    </option>
                  ))}
              </select>
            </div>
            {errors.apartmentId && (
              <div className="error-form-container">
                <span className="error-text">
                  * Debes seleccionar el apartamento
                </span>
              </div>
            )}

            <div className="skew-input w-100 mx-auto">
              <FormInput
                value={owner.password || ""}
                onChange={this.handleInputChangePassword}
                name="password"
                id="password"
                type="password"
                placeholder="Contraseña"
                required
              />
            </div>
            {errors.password && (
              <div className="error-form-container">
                <span className="error-text">
                  * Debes ingresar una contraseña
                </span>
              </div>
            )}
            <div className="skew-input w-100 mx-auto">
              <FormInput
                value={passwordConfirmation || ""}
                onChange={this.handlePasswordConfirm}
                name="passwordConfirmation"
                id="passwordConfirmation"
                type="password"
                placeholder="Confirmar contraseña"
                required
              />
            </div>
            {errors.passwordConfirmation && (
              <div className="error-form-container">
                <span className="error-text">
                  * Debes confirmar la contraseña
                </span>
              </div>
            )}
            {error && (
              <div className="error-form-container">
                <span className="error-text">{error}</span>
              </div>
            )}

            <div className="form-check">
              <input
                id="termsCheckbox"
                className="form-check-input"
                onChange={this.handleCheckboxChange}
                type="checkbox"
                name="acceptTerms"
                checked={owner.acceptTerms || false}
              />
              <label className="form-check-label" htmlFor="termsCheckbox">
                He leido y acepto los
              </label>
            </div>
            <strong
              className="terms-condititions-link"
              onClick={() => this.setState({ showTermsModal: true })}
            >
              Términos y condiciones
            </strong>
            {showTermsModal ? (
              <TerminosYCondiciones
                onModalClosed={this.handleCloseTermsModal.bind(this)}
              />
            ) : null}
            {errors.acceptTerms && (
              <div className="error-form-container" style={{ marginTop: 0 }}>
                <span className="error-text">
                  * Debes aceptar los términos y condiciones
                </span>
              </div>
            )}

            <div className="w-100 mx-auto d-flex justify-content-center">
              {loading ? (
                <Loader />
              ) : (
                <FormButton
                  type="submit"
                  value="Registrarse como Propietario"
                  textbutton="Registrarse como Propietario"
                  className="btn w-100 mx-auto button-form mt-1"
                />
              )}
            </div>
          </form>
        </div>
      </div>
    );
  }

  goBack() {
    window.history.back();
  }

  fixPhone = (phone) => `+57${phone.replace(/\s/g, "")}`;

  handleSubmit = async (event) => {
    this.setState({
      loading: true,
    });
    try {
      event.preventDefault();
      const { owner, formData, error, previewPhoto } = this.state;
      const mandatoryFields = validateMandatoryFields(
        { ...owner, previewPhoto },
        [
          "name",
          "cedula",
          "email",
          "phone",
          "password",
          "towerId",
          "floorId",
          "buildingId",
          "apartmentId",
          "previewPhoto",
        ]
      );
      if (!owner.acceptTerms) {
        mandatoryFields.acceptTerms = "* El campo es obligatorio";
      }

      this.setState({ errors: mandatoryFields });
      if (!Object.keys(mandatoryFields).length && error === null) {
        formData.set("name", this.state.owner.name);
        formData.set("cedula", this.state.owner.cedula);
        formData.set("documentType", this.state.owner.documentType);
        formData.set("password", this.state.owner.password);
        formData.set(
          "passwordConfirmation",
          this.state.owner.passwordConfirmation
        );
        formData.set("phone", this.fixPhone(this.state.owner.phone));
        formData.set("email", this.state.owner.email);
        formData.set("buildingId", this.state.owner.buildingId);
        formData.set("towerId", this.state.owner.towerId);
        formData.set("floorId", this.state.owner.floorId);
        formData.set("apartmentId", this.state.owner.apartmentId);
        const ownerRegistred = await registerApartmentOwner(
          owner.apartmentId,
          formData
        );
        console.log("response", ownerRegistred);
        this.setState({
          loading: false,
        });
        this.props.history.push("/login");
      } else {
        this.setState({
          loading: false,
        });
        showErrorAlert("ups", "Por favor completar los campos requeridos");
      }
    } catch (error) {
      const errorsList = getErrorsList(error);
      showErrorList(errorsList);
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  handleInputChange = (event) => {
    this.setState({
      owner: {
        ...this.state.owner,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleCheckboxChange = (event) => {
    const name = event.target.name;
    const newValue = !this.state.owner[name];

    this.setState({
      owner: {
        ...this.state.owner,
        [name]: newValue,
      },
    });
  };

  handleBuildingChange = async (event) => {
    try {
      this.updateOwnerState(event.target.name, event.target.value);
      const towers = event.target.value
        ? await getBuildingTowers(event.target.value)
        : { data: [] };
      this.updateState("towers", towers.data);
    } catch (error) {
      console.error(
        `FormOwnerApartmentOwnerRegister -> handleBuildingChange -> error`,
        error
      );
    }
  };

  handleTowerChange = async (event) => {
    try {
      this.updateOwnerState(event.target.name, event.target.value);
      const floors = await getTowerFloors(event.target.value);
      this.updateState("floors", floors.data);
    } catch (error) {
      console.log(
        `FormOwnerApartmentOwnerRegister -> handleTowerChange -> error`,
        error
      );
    }
  };

  handleFloorChange = async (event) => {
    try {
      this.updateOwnerState(event.target.name, event.target.value);
      const apartments = await getFloorApartmentsService(
        event.target.value,
        false
      );
      console.log({ apartments });
      this.updateState("apartments", apartments.data);
    } catch (error) {
      console.log(
        `FormOwnerApartmentOwnerRegister -> handleFloorChange -> error`,
        error
      );
    }
  };

  handleApartmentChange = async (event) => {
    this.updateOwnerState(event.target.name, event.target.value);
  };

  handleInputChangePassword = (event) => {
    this.setState({
      owner: Object.assign({}, this.state.owner, {
        [event.target.name]: event.target.value,
      }),
    });
    if (this.state.owner.passwordConfirmation !== "") {
      if (event.target.value !== this.state.owner.passwordConfirmation) {
        this.setState({ error: "Las contraseñas no coinciden" });
      } else {
        this.setState({ error: null });
        this.updateOwnerState(
          "passwordConfirmation",
          this.state.owner.passwordConfirmation
        );
      }
    }
  };

  handlePasswordConfirm = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.value !== this.state.owner.password) {
      this.setState({ error: "Las contraseñas no coinciden" });
    } else {
      this.setState({ error: null });
      this.updateOwnerState("passwordConfirmation", event.target.value);
    }
  };

  handleImage = async (event) => {
    let files = event.target.files;
    let reader = new FileReader();
    this.state.formData.set("photo", files[0]);
    reader.readAsDataURL(files[0]);
    reader.onloadend = () => {
      this.setState({
        previewPhoto: reader.result,
        formData: this.state.formData,
        owner: {
          ...this.state.owner,
        },
      });
    };
  };

  updateOwnerState = (name, value) => {
    this.setState({
      owner: {
        ...this.state.owner,
        [name]: value,
      },
    });
  };

  updateState = (name, value) => {
    this.setState({
      [name]: value,
    });
  };
}

FormApartmentOwnerRegister.propTypes = {
  history: PropTypes.object,
};

export default FormApartmentOwnerRegister;
