import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route } from 'react-router-dom'
import { checkCurrentUser } from '../utils/token'

export const PrivateRoute = ({ component: Component, ...rest }) => {
  checkCurrentUser()
  return (
    <Route
      {...rest}
      render={
        props =>
          localStorage.getItem('authToken') ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location }
              }}
            />)
      }
    />)
}

PrivateRoute.propTypes = {
  location: PropTypes.object,
  component: PropTypes.func
}
