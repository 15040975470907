import { url_base } from "../../config/url";
import axios from "axios";

export async function getFloorApartmentsService(floorId, active = true) {
  return axios({
    method: "get",
    url: `${url_base}/api/floors/${floorId}/apartments?active=${active}`,
    headers: {
      "Content-Type": "application/json",
    },
  });
}
