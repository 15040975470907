export function validateMandatoryFields (values = {}, fields = []) {
  const errors = fields.reduce((acum, value) => {
    if (values[value] === '' || values[value] === null) {
      return {
        ...acum,
        [value]: '* El campo es obligatorio'
      }
    } else {
      return acum
    }
  }, {})
  return errors
}
