import React, { Component } from 'react'
import PropTypes from 'prop-types'
import '../assets/css/camaras.css'

export class Mapa extends Component {
  render () {
    // TODO: remove each one of props and let only door
    return (
      <button
        className='btn-camara'
        data-toggle={this.props.datatoggle}
        data-target={this.props.datatarget}
        style={{
          left: `${this.props.cam01Left}%`,
          top: `${this.props.cam01Top}%`
        }}
        onClick={() => this.props.cameraSelected(this.props.door)}
      >
        {this.props.nameCamara}
      </button>
    )
  }
}

Mapa.propTypes = {
  door: PropTypes.object,
  datatoggle: PropTypes.string,
  datatarget: PropTypes.string,
  cam01Left: PropTypes.number || PropTypes.string,
  cam01Top: PropTypes.number || PropTypes.string,
  cameraSelected: PropTypes.func,
  doorId: PropTypes.number,
  nameCamara: PropTypes.string
}

export default Mapa
