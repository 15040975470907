import React, { Component } from "react";
import PropTypes from "prop-types";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner";

export class FormInput extends Component {
  render() {
    return (
      <input
        id={this.props.id}
        type={this.props.type}
        name={this.props.name}
        placeholder={this.props.placeholder}
        onChange={this.props.onChange}
        value={this.props.value}
        disabled={this.props.isDisabled || false}
        readOnly={this.props.isReadOnly || false}
        className="w-100"
      />
    );
  }
}

FormInput.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
};

export class FormButton extends Component {
  render() {
    return (
      <button
        onClick={this.props.onClick}
        type={this.props.type}
        href={this.props.href}
        name={this.props.name}
        value={this.props.value}
        disabled={this.props.disabled}
        className={`btn w-100 mx-auto ${this.props.className}`}
      >
        {this.props.isLoading && <LoadingSpinner size={20} />}
        {this.props.textbutton}
      </button>
    );
  }
}

FormButton.propTypes = {
  value: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  href: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  textbutton: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default (FormInput, FormButton);
