import React from "react";

import { withRouter } from "react-router-dom";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import {
  showConfirmAlert,
  showSuccessAlert,
  showErrorList,
} from "../../utils/alerts";

import { getApartmentsByBuilding, deleteApartment } from "../../utils/api.js";

const Apartments = (props) => {
  const { buildingId } = props.match.params;
  const [buildings, setBuildings] = useState([]);

  const getAllapartments = async () => {
    try {
      const data = await getApartmentsByBuilding(buildingId);

      setBuildings(data);
    } catch (error) {
      console.error("Error", error);
    }
  };
  const confirmDeleteApartment = async ({ id }) => {
    try {
      await deleteApartment(buildingId, id);

      showSuccessAlert("Borrado Exitoso", "Apartamento borrado con éxito");
    } catch (error) {
      showErrorList(error);
      console.error("Error", error);
    }
  };

  const handleDeleteApartmentClick = (id) => {
    showConfirmAlert({
      message: "Se borrará el apartamento y se creará uno nuevo.",
      title: "Advertencia",
      cancelButton: "Cancelar",
      continueButton: "Borrar el Apartamento",
      onConfirm: () => {
        confirmDeleteApartment({ id });
      },
    });
  };
  useEffect(() => {
    getAllapartments();
  }, []);

  return (
    <>
      <div>
        <div className="row w-100 text-form">
          <h2>Administrar Apartamentos</h2>
        </div>

        <div className="container table-apartments">
          <table className="table table-striped">
            <thead className="text-center font-weight-bold text-white">
              <tr>
                <th scope="col">Nombre</th>
                <th scope="col">Estado</th>
                <th scope="col">Acción</th>
              </tr>
            </thead>
            <tbody>
              {buildings.map((building) => {
                const { id, name, active } = building;
                return (
                  <tr key={id}>
                    <td className="align-middle">{name}</td>
                    <td className="align-middle">
                      {active ? "activo" : "Inactivo"}
                    </td>
                    <td className="align-middle">
                      <button
                        type="button"
                        className="btn"
                        onClick={() => {
                          handleDeleteApartmentClick(id);
                        }}
                      >
                        <span className="material-icons">delete</span>
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
Apartments.propTypes = {
  buildingId: PropTypes.text,
  match: PropTypes.object,
};
export default withRouter(Apartments);
