import React from 'react';

import PropTypes from 'prop-types';

const AdminMenu = ({ history }) => {
  return (
    <>
      <div className="row w-100 text-form justify-content-center">
        <h2 className="w-100">Administración de Edificios</h2>
        <p />
        <div>
          <button
            className="admin-button"
            onClick={() => {
              history.push('/admin-dashboard/card-manager');
            }}
          >
            Administrar Tarjetas
          </button>
          <button
            className="admin-button"
            onClick={() => {
              history.push('/admin-dashboard/buildings');
            }}
          >
            Administrar Edificios
          </button>
        </div>
      </div>
    </>
  );
};
AdminMenu.propTypes = {
  history: PropTypes.object,
};
export default AdminMenu;
