export const getErrorMessage = (error) => {
  console.log(`CardManager -> error`, error.message);

  if (error.message.includes("400")) {
    return "La tarjeta ya ha sido asignada y/ el usuario ya tiene tarjeta asignada.";
  } if (error.message.includes("401")) {
    return "No posee permisos para realizar esta acción.";
  } if (error.message.includes("404")) {
    return "No se encontró el usuario ingresado.";
  }
  if (error.message.includes("422")) {
    return "Por favor ingresar toda la información solicitada.";
  }
  if (error.message.includes("500")) {
    return "Lo sentimos ha ocurrido un error inesperado, comuníquese con un administrador";
  }
  return "Lo sentimos ha ocurrido un error inesperado, comuníquese con un administrador";
};
