import { url_base } from "../../config/url";
import axios from "axios";

export function blueBuildingAuthLogin(data) {
  return axios({
    method: "post",
    url: `${url_base}/api/bluebuilding/auth/login`,
    headers: {
      "Content-Type": "application/json",
    },
    maxContentLength: 2000,
    maxBodyLength: 2000,
    data,
  });
}

export function blueBuildingAssignCard(data) {
  return axios.post(`${url_base}/api/bluebuilding/cards`, data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("authToken")}`,
      "content-type": "multipart/form-data",
    },
  });
}

export function blueBuildingReactivateCard(data) {
  return axios.post(`${url_base}/api/bluebuilding/cards/reactivate`, data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("authToken")}`,
      "Content-Type": "application/json",
    },
  });
}

export async function getUserByCedula(cedula) {
  if (!cedula) {
    return null;
  }

  const result = await axios({
    method: "get",
    url: `${url_base}/api/bluebuilding/users/${cedula}`,
    headers: {
      Authorization: `JWT ${localStorage.getItem("authToken")}`,
      "Content-Type": "application/json",
    },
  });

  return result?.data?.user;
}
