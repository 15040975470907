import { url_base } from "../config/url";
import axios from "axios";

export function get_edificios() {
  return axios.get(url_base + "/api/building");
}
export function get_torres(id) {
  return axios.get(url_base + "/api/tower/" + id);
}

export function get_aptos(id) {
  return axios.get(url_base + "/api/apartment/" + id);
}

export function get_apto(id) {
  return axios.get(url_base + "/api/apartment/" + id);
}

export async function getApartmentsByBuilding(buildingId) {
  const token = localStorage.getItem("authToken");
  const result = await axios({
    method: "get",
    url: `${url_base}/api/bluebuilding/buildings/${buildingId}/apartments`,
    headers: {
      Authorization: `JWT ${token}`,
      "Content-Type": "application/json",
    },
  });
  return result?.data;
}

export async function getBuildingsList() {
  const token = localStorage.getItem("authToken");
  const result = await axios({
    method: "get",
    url: `${url_base}/api/bluebuilding/buildings`,
    headers: {
      Authorization: `JWT ${token}`,
      "Content-Type": "application/json",
    },
  });

  return result?.data;
}

export async function deleteApartment(buildingId, apartmentId) {
  const token = localStorage.getItem("authToken");

  const result = await axios({
    method: "delete",
    url: `${url_base}/api/bluebuilding/buildings/${buildingId}/apartments/${apartmentId}/`,

    headers: {
      Authorization: `JWT ${token}`,
      "Content-Type": "application/json",
    },
  });

  return result?.data;
}

export async function getApartmentUsersByName(filterValue) {
  const buildingId = localStorage.getItem("buildingId");
  const result = await axios({
    method: "get",
    url: `${url_base}/api/buildings/${buildingId}/apartments/${filterValue}`,
    headers: {
      Authorization: `JWT ${localStorage.getItem("authToken")}`,
      "Content-Type": "application/json",
    },
  });

  return result?.data?.apartmentUsers || [];
}

export function registro(user, device) {
  return axios({
    method: "post",
    url: url_base + "/user",
    headers: {
      device: device,
      "Content-Type": "application/json",
    },
    data: user,
  });
}

export function login(data) {
  return axios({
    method: "post",
    url: url_base + "/auth/login",
    data: data,
  });
}

export function recoveryEmail(data) {
  return axios({
    method: "post",
    url: url_base + "/email/recovery",
    data: data,
  });
}

export function changePassword(data, token) {
  console.log(token);
  return axios({
    method: "post",
    url: url_base + "/auth/change-password",
    headers: {
      Authorization: "JWT " + token,
      "Content-Type": "application/json",
    },
    data: data,
  });
}

export function get_user_info(user) {
  return axios({
    method: "post",
    url: url_base + "/user/user_email",
    headers: {
      auth: localStorage.getItem("authToken"),
      "Content-Type": "application/json",
    },
    data: {
      email: user,
    },
  });
}

export function get_camera(id) {
  return axios({
    method: "get",
    url: url_base + "/api/camera/" + id,
    headers: {
      Authorization: `JWT ${localStorage.getItem("authToken")}`,
      "Content-Type": "application/json",
    },
  });
}
export function get_levels(id) {
  return axios({
    method: "get",
    url: url_base + "/select/niveles/" + id,
    headers: {
      auth: localStorage.getItem("authToken"),
      "Content-Type": "application/json",
    },
  });
}

export function getApartamentCorrespondence() {
  return axios({
    method: "get",
    url: url_base + "/api/select/apartment/building",
    headers: {
      Authorization: "JWT " + localStorage.getItem("authToken"),
      "Content-Type": "application/json",
    },
  });
}

export function getZones() {
  return axios({
    method: "get",
    url: url_base + "/api/zone",
    headers: {
      Authorization: "JWT " + localStorage.getItem("authToken"),
      "Content-Type": "application/json",
    },
  });
}

export function getCorrespondence() {
  return axios({
    method: "get",
    url: url_base + "/api/correspondence",
    headers: {
      Authorization: "JWT " + localStorage.getItem("authToken"),
      "Content-Type": "application/json",
    },
  });
}
export function getSolicitudes() {
  return axios({
    method: "get",
    url: url_base + "/api/petition",
    headers: {
      Authorization: "JWT " + localStorage.getItem("authToken"),
      "Content-Type": "application/json",
    },
  });
}
export function getVotaciones() {
  return axios({
    method: "get",
    url: url_base + "/api/vote",
    headers: {
      Authorization: "JWT " + localStorage.getItem("authToken"),
      "Content-Type": "application/json",
    },
  });
}
export function getReservaciones() {
  return axios({
    method: "get",
    url: url_base + "/api/reservation",
    headers: {
      Authorization: "JWT " + localStorage.getItem("authToken"),
      "Content-Type": "application/json",
    },
  });
}
export function get_puertas(id) {
  return axios({
    method: "get",
    url: url_base + "/api/floor/" + id,
    headers: {
      Authorization: "JWT " + localStorage.getItem("authToken"),
      "Content-Type": "application/json",
    },
  });
}

export function get_carteleras() {
  return axios({
    method: "get",
    url: url_base + "/api/billboard",
    headers: {
      Authorization: "JWT " + localStorage.getItem("authToken"),
      "Content-Type": "application/json",
    },
  });
}

export function get_logs() {
  return axios({
    method: "get",
    url: url_base + "/files/logs",
    headers: {
      auth: localStorage.getItem("authToken"),
      "Content-Type": "application/json",
    },
  });
}
export function saveAll(data, endpoint) {
  return axios({
    method: "post",
    url: url_base + endpoint,
    headers: {
      Authorization: "JWT " + localStorage.getItem("authToken"),
      "Content-Type": "application/json",
    },
    data: data,
  });
}

export function updateItem(data, endpoint) {
  return axios({
    method: "put",
    url: url_base + endpoint,
    headers: {
      Authorization: "JWT " + localStorage.getItem("authToken"),
      "Content-Type": "application/json",
    },
    data: data,
  });
}

export function votar(data, endpoint) {
  return axios({
    method: "post",
    url: url_base + endpoint,
    headers: {
      Authorization: "JWT " + localStorage.getItem("authToken"),
      "Content-Type": "application/json",
    },
    data: data,
  });
}

export function deleteAll(id, endpoint) {
  return axios({
    method: "delete",
    url: url_base + endpoint + "/" + id,
    headers: {
      Authorization: "JWT " + localStorage.getItem("authToken"),
      "Content-Type": "application/json",
    },
  });
}

export async function createCall(apartmentUserId, type) {
  const result = await axios({
    method: "post",
    url: `${url_base}/api/call`,
    headers: {
      Authorization: `JWT ${localStorage.getItem("authToken")}`,
      "Content-Type": "application/json",
    },
    data: {
      apartmentUserId,
      type,
    },
  });

  return result?.data;
}

export function createTwilioCall(apartmentUsersId, buildingId) {
  return axios({
    method: "post",
    url: `${url_base}/api/call-twilio`,
    headers: {
      Authorization: `JWT ${localStorage.getItem("authToken")}`,
      "Content-Type": "application/json",
    },
    data: {
      apartmentUsersId,
      buildingId,
    },
  });
}

export function updateCall(callId, data) {
  return axios({
    method: "put",
    url: url_base + "/api/call/" + callId,
    headers: {
      Authorization: "JWT " + localStorage.getItem("authToken"),
      "Content-Type": "application/json",
    },
    data: data,
  });
}

export async function getCall(callId) {
  try {
    const {
      data: { call },
    } = await axios({
      method: "get",
      url: `${url_base}/api/call/${callId}`,
      headers: {
        Authorization: `JWT ${localStorage.getItem("authToken")}`,
        "Content-Type": "application/json",
      },
    });
    return call;
  } catch (error) {
    console.error("getCall --> Error", error);
    return null;
  }
}

export function getApartmentVisitors(apartmentId) {
  return axios({
    method: "get",
    url: `${url_base}/api/apartments/${apartmentId}/visitors`,
    headers: {
      Authorization: `JWT ${localStorage.getItem("authToken")}`,
      "Content-Type": "application/json",
    },
  });
}

export function createApartmentVisitor(apartmentId, data) {
  return axios({
    method: "post",
    url: `${url_base}/api/apartments/${apartmentId}/visitors`,
    headers: {
      Authorization: `JWT ${localStorage.getItem("authToken")}`,
      "Content-Type": "multipart/form-data",
    },
    data: data,
  });
}
