import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { isFunction as _isFunction } from "lodash";
import PeerConnection from "../../helpers/PeerConnection";
/** Components */
import Modal from "react-responsive-modal";

const CallingModal = ({ call, onCloseCallingModal, isFromLobby }) => {
  /** the video I'm broadcasting */
  const [myLocalVideo, setMyLocalVideo] = useState(null);
  const [myLocalSrc, setMyLocalSrc] = useState(null);

  /** the video I'm receiving from another device */
  const [peerVideo, setPeerVideo] = useState(null);
  const [peerSrc, setPeerSrc] = useState(null);
  const [showModal, setShowModal] = useState(true);

  let peerConnection;

  const setMediaStream = () => {
    if (myLocalVideo && myLocalSrc) {
      myLocalVideo.srcObject = myLocalSrc;
      // it seems iphone doesn't allow videos to autoplay if they are not muted,
      // but as we are streaming video calls, we should not have muted calls,
      // so we need to play videos automatically once we load its source
      // - autoplay muted playsInline - works
      // - autoplay playsInline - doesn't work (unmuted)
      myLocalVideo.onloadedmetadata = function () {
        myLocalVideo.play();
      };
    }
    // TODO: this is machete change this we need a better way to not stream
    // apartment user's video, but for now this is the best option
    if (peerVideo && peerSrc) {
      peerVideo.srcObject = peerSrc;
      peerVideo.onloadedmetadata = function () {
        peerVideo.play();
        peerVideo.muted = false;
      };
    }
  };

  const createPeerConnection = () => {
    const config = {
      audio: { echoCancellation: true, noiseSuppresion: true },
      // video: isFromLobby ? { facingMode: "user" } : false,
      video: { facingMode: "user" },
    };

    peerConnection = new PeerConnection(call.id, call.userId)
      .on("localStream", (myLocalStream) => {
        setMyLocalSrc(myLocalStream);
      })
      .on("peerStream", (stream) => {
        setPeerSrc(stream);
      })
      .start(true, config);
  };

  const endPeerConnection = () => {
    if (!peerConnection) return;
    if (peerConnection && _isFunction(peerConnection.stop)) {
      peerConnection.stop(true);
      peerConnection = null;
    }
  };

  const endCall = () => {
    endPeerConnection();
    setMyLocalSrc(null);
    setMyLocalVideo(null);
    setPeerVideo(null);
    setPeerSrc(null);
    setShowModal(false);
    onCloseCallingModal();
  };

  useEffect(() => {
    createPeerConnection();
    return () => endCall();
  }, [call]);

  useEffect(() => {
    setMediaStream();
  }, [myLocalSrc, peerSrc]);

  return (
    <Modal
      open={showModal}
      classNames={{ overlay: "background-modal" }}
      onClose={endCall}
      center
    >
      <div className="modal-content">
        <div className={"header-modal-new"}>
          <button type="button" className="button-close" onClick={endCall}>
            <span aria-hidden="true" className="button-span">
              &times;
            </span>
          </button>
        </div>
        <div className={"body-modal-new"}>
          <video
            id="localVideo-new"
            style={{ display: isFromLobby ? "inline" : "none" }}
            ref={(el) => setMyLocalVideo(el)}
            muted
            playsInline
          />
          <video
            id="peerVideo-new"
            style={{ display: isFromLobby ? "none" : "inline" }}
            ref={(el) => setPeerVideo(el)}
            muted
            playsInline
          />
        </div>
        <div className="footer-modal-new">
          <button
            type="button"
            className="btn btn-danger call-button"
            onClick={endCall}
          >
            <span className="material-icons">call_end</span>
          </button>
        </div>
      </div>
    </Modal>
  );
};

CallingModal.propTypes = {
  isFromLobby: PropTypes.bool,
  call: PropTypes.objectOf(PropTypes.any).isRequired,
  onCloseCallingModal: PropTypes.func,
};

CallingModal.defaultProps = {
  isFromLobby: false,
};
export default CallingModal;
