import React, { Component } from 'react'
import '../assets/css/profile.css'
// import ListaFamilia from './listaFamilia'
// import ListaVehiculos from './listaVehiculos'

export class ProfileTabs extends Component {
  render () {
    return (
      <div className='integrantes'>
        <ul className='nav nav-pills nav-fill' id='myTab' role='tablist'>
          <li className='nav-item'>
            <a
              className='nav-link active'
              id='familia-tab'
              data-toggle='tab'
              href='#familia'
              role='tab'
              aria-controls='familia'
              aria-selected='true'
            >
              Familia
            </a>
          </li>
          <li className='nav-item'>
            {/* <a
              className='nav-link'
              id='vehiculos-tab'
              data-toggle='tab'
              href='#vehiculos'
              role='tab'
              aria-controls='vehiculos'
              aria-selected='false'
            >
              Vehículos
            </a> */}
          </li>
        </ul>
        <div className='tab-content' id='myTabContent'>
          <div
            className='tab-pane fade show active'
            id='familia'
            role='tabpanel'
            aria-labelledby='familia-tab'
          >
            {/* <ListaFamilia /> */}
          </div>
          {/* <div
            className='tab-pane fade'
            id='vehiculos'
            role='tabpanel'
            aria-labelledby='vehiculos-tab'
          >
            <ListaVehiculos />
          </div> */}
        </div>
      </div>
    )
  }
}

export default ProfileTabs
