import React, { Component } from 'react'
import PropTypes from 'prop-types'
/** Components */
import AccessRequestRow from '../../components/accessRequestRow'
/** Services / UTILS */
import {
  getOwnerAccess,
  grantOwnerAccess
} from '../../utils/services/buildingService'
import { showSuccessAlert, showErrorAlert } from '../../utils/alerts'
/** CSS */
import '../../assets/css/votaciones.css'
import '../../assets/css/cartelera.css'

export class OwnersApartmentRequests extends Component {
  constructor (props) {
    super(props)
    this.state = {
      user: {
        id: '',
        email: '',
        name: '',
        phone: ''
      },
      building: {
        id: '',
        email: '',
        name: '',
        phone: '',
        address: '',
        city: '',
        state: '',
        nit: ''
      },
      apartment: {
        id: '',
        name: ''
      },
      accessRequests: [],
      error: null
    }
  }

  componentDidMount = async () => {
    try {
      if (localStorage.getItem('authToken')) {
        const ownerAccesses = await getOwnerAccess(
          localStorage.getItem('buildingId'),
          localStorage.getItem('authToken')
        )
        console.log(
          `OwnersApartmentRequests -> componentDidMount -> ownerAccesses`,
          ownerAccesses.data.accessRequests
        )
        this.setState({
          building: {
            id: localStorage.getItem('buildingId')
          },
          token: localStorage.getItem('authToken'),
          accessRequests: ownerAccesses.data.accessRequests
        })
      } else {
        this.props.history.push('/')
      }
    } catch (error) {
      console.log(
        `OwnersApartmentRequests -> componentDidMount -> error`,
        error
      )
    }
  }

  render () {
    return (
      <div className='container-section'>
        <div className='row info titulo'>
          <div className='col-12 text-left pr-0'>
            <h2>Propietarios/Líderes Pendientes de aprobación</h2>
          </div>
        </div>
        <div className='noticias'>
          <ul>
            {this.state.accessRequests.length > 0 ? (
              this.state.accessRequests.map((accessRequest, index) => (
                <AccessRequestRow
                  key={index}
                  title={accessRequest.apartment.name}
                  subtitle={`Empleado: ${accessRequest.user.name} / ${accessRequest.user.cedula}`}
                  description={`Tel: ${accessRequest.user.phone}`}
                  approvedAction={this.handleOnClickApprovedButton}
                  notApprovedAction={this.handleOnClickNotApprovedButton}
                  id={accessRequest.id}
                />
              ))
            ) : (
              <div style={{margin: '1em'}}>
                <div className='alert alert-info' role='alert'>
                  No hay solicitudes pendientes por aprobar
                </div>
              </div>
            )}
          </ul>
        </div>
      </div>
    )
  }

  handleOnClickApprovedButton = async accessRequestId => {
    try {
      const accessRequest = this.state.accessRequests.find(
        obj => obj.id === accessRequestId
      )
      await grantOwnerAccess(
        this.state.building.id,
        accessRequest.id,
        localStorage.getItem('authToken'),
        {
          approved: true,
          apartmentId: accessRequest.apartment.id
        }
      )
      const accessRequestsFiltered = this.state.accessRequests.filter(
        obj => obj.id !== accessRequestId
      )
      this.setState({
        accessRequests: accessRequestsFiltered
      })
      showSuccessAlert('¡Aprobado!', 'Acceso otorgado con éxito')
    } catch (error) {
      console.log('handleOnClickApprovedButton error', error)
      showErrorAlert('Ups!', 'Hubo un error al aprobar esta solicitud')
    }
  }

  handleOnClickNotApprovedButton = async accessRequestId => {
    try {
      const accessRequest = this.state.accessRequests.find(
        obj => obj.id === accessRequestId
      )
      await grantOwnerAccess(
        this.state.building.id,
        accessRequest.id,
        this.state.token,
        {
          approved: false,
          apartmentId: accessRequest.apartment.id
        }
      )
      const accessRequestsFiltered = this.state.accessRequests.filter(
        obj => obj.id !== accessRequestId
      )
      this.setState({
        accessRequests: accessRequestsFiltered
      })
      showSuccessAlert(
        '¡Rechazado!',
        'Se ha rechazado el acceso a este usuario'
      )
    } catch (error) {
      console.log('handleOnClickNotApprovedButton error', error)
      showErrorAlert('Ups!', 'Hubo un error al rechazar la solicitud')
    }
  }
}

OwnersApartmentRequests.propTypes = {
  history: PropTypes.object
}

export default OwnersApartmentRequests
