import { url_base } from "../../config/url";
import axios from "axios";

export function userAuthLogin(data) {
  return axios({
    method: "post",
    url: `${url_base}/api/user/auth/login`,
    headers: {
      "Content-Type": "application/json",
    },
    maxContentLength: 2000,
    maxBodyLength: 2000,
    data,
  });
}

export function userAuthForgotPassword(data) {
  return axios({
    method: "post",
    url: `${url_base}/api/user/auth/forgotPassword`,
    headers: {
      "Content-Type": "application/json",
    },
    maxContentLength: 2000,
    maxBodyLength: 2000,
    data,
  });
}

export function userAuthResetPassword(data) {
  return axios({
    method: "post",
    url: `${url_base}/api/user/auth/resetPassword`,
    headers: {
      "Content-Type": "application/json",
    },
    maxContentLength: 2000,
    maxBodyLength: 2000,
    data,
  });
}

export function getLobbyUser(buildingId) {
  return axios({
    method: "get",
    url: `${url_base}/api/buildings/${buildingId}/lobby-user`,
    headers: {
      Authorization: `JWT ${localStorage.getItem("authToken")}`,
      "Content-Type": "application/json",
    },
    maxContentLength: 2000,
    maxBodyLength: 2000,
  });
}

/** Just map de current User Result to objet with first space */
export function getUserInfo(data) {
  if (!data) {
    return null;
  }
  let user = {
    user: data.user,
  };
  if (Array.isArray(data.spaces) && data.spaces.length > 0) {
    const space = data.spaces[0];
    user = {
      ...user,
      apartment: space.apartment,
      building: space.building,
      role: space.role,
    };
  }
  return user;
}

export function saveNotificationsToken(data) {
  return axios({
    method: "post",
    url: `${url_base}/api/user/notifications/token`,
    headers: {
      Authorization: `JWT ${localStorage.getItem("authToken")}`,
      "Content-Type": "application/json",
    },
    maxContentLength: 2000,
    maxBodyLength: 2000,
    data,
  });
}

export async function setUserAsValidated(data) {

  const result = await axios({
    method: "post",
    url: `${url_base}/api/bluebuilding/userv2`,

    headers: {
      Authorization: `JWT ${localStorage.getItem("authToken")}`,
      "Content-Type": "application/json",
    },
    data,
  });

  return result?.data;
}