import React, { useState } from "react";
import { NavLink } from "react-router-dom";
/** Components */
import Menu from "./menu";
import Caller from "./Call/Caller";
import SuspectModal from "./suspectModal";
import { Panicoactivo } from "./modalPanico";
/** Services */
import { createCall } from "../utils/api";
import { getLobbyUser } from "../utils/services/userService";
/** CSS */
import "../assets/css/menu.css";

const MenuInferior = () => {
  const [showModal, setShowModal] = useState(false);
  const [call, setCall] = useState(null);

  const callToLobby = async () => {
    try {
      const buildingId = localStorage.getItem("buildingId");
      const {
        data: { user: lobbyUser },
      } = await getLobbyUser(buildingId);

      if (lobbyUser) {
        const call = await createCall(lobbyUser.apartmentUser.id, "VISITANTE");
        setCall({
          ...call,
          userId: lobbyUser.id,
        });
      }
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <div>
      <nav className="menuinferior fixed-bottom w-100">
        <ul>
          <li>
            <button onClick={() => callToLobby()}>
              <span
                className="form-icon material-icons"
                style={{ fontSize: 40, color: "#00bfdf" }}
              >
                room_service
              </span>
            </button>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              to="/solicitudes"
              className="ico-mensaje"
              data-toggle="popover"
              data-content="Disabled popover"
            />
            {/* <Notificacion notificaciones='2' visibility='visible' /> */}
          </li>
          <li>
            <NavLink to="/correspondencia" className="ico-correspondencia" />
            {/* <Notificacion notificaciones='1' visibility='visible' /> */}
          </li>
          <li>
            <NavLink to="/camaras" className="ico-cartelera" />
          </li>
          <li>
            <button onClick={() => setShowModal(true)} className="ico-panico" />
          </li>
          <li>
            <a
              className="ico-menu navbar-toggler"
              href="#navbarNavDropdown"
              data-toggle="collapse"
              data-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            />
          </li>
        </ul>
        {showModal && (
          <SuspectModal onModalClosed={() => setShowModal(false)} />
        )}
        <Panicoactivo />
      </nav>

      <Menu />
      {call ? (
        <Caller calls={[call]} onCloseCaller={() => setCall(null)} />
      ) : null}
    </div>
  );
};

export default MenuInferior;
