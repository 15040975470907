import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import Modal from "react-responsive-modal";
import socket from "../../utils/socket";
/** Components */
import { LogoPeque } from "../../logo";
/** Services  */
import { updateCall } from "../../utils/api";
import incommingCallAudio from "../../assets/audio/incomming-call.mp3";

class IncomingCall extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      callFrom: null,
      callId: null,
      peerSrc: null,
      isFromLobby: false,
      audio: new Audio(incommingCallAudio),
    };

    this.getCallingMessage = this.getCallingMessage.bind(this);
    this.handleRejectCall = this.handleRejectCall.bind(this);
  }

  rejectMessages = [
    "Favor dejar el domicilio en el buzón",
    "Abre la puerta al escuchar el pito y la lectora esté verde",
    "Enterado! Ya nos vemos en la portería. Esperame!",
    "Dame un momento, estoy retrasado",
    "No me encuentro disponible en el momento. Vuelve luego",
    "Hablamos por celular en cuando pueda. Vuelve después",
  ];

  playAudio = () => {
    const { audio } = this.state;
    if (audio.paused) {
      audio.play();
    } else {
      audio.currentTime = 0;
    }
  };

  pauseAudio = () => {
    this.state.audio.pause();
  };

  componentDidMount() {
    socket
      // from: { id, name }
      .on("request", ({ from, callId, isFromLobby }) => {
        this.playAudio();
        console.log(`%c ${callId}`, "color: green");
        console.log({ isFromLobby, callId, from });

        this.setState({
          showModal: true,
          callFrom: from,
          callId: callId,
          isFromLobby,
        });
        socket.on("reject_call", this.closeCall.bind(this));
      })
      // we need this message here if user has multiple sessions so we need to
      // notify the other sessions that the call was already accepted
      .on("call_accepted:from_other_session", this.closeCall.bind(this));
  }

  componentWillUnmount() {
    // Clean up the audio object when the component is unmounted
    this.state.audio.pause();
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.audio.currentTime = 0;
  }

  closeCall() {
    this.pauseAudio();

    this.setState((prevState) => ({
      ...prevState,
      callFrom: null,
      callId: null,
      showModal: false,
      peerSrc: null,
      isFromLobby: false,
    }));

    socket.off("reject_call");
  }

  async handleRejectCall(rejectMessage) {
    try {
      await updateCall(this.state.callId, { status: "RECHAZADA" });
    } catch (e) {
      console.error(e);
    }
    // the stream is already started even if we haven't accept/reject the call
    // so we still need to close the stream and then end the connection from the
    // peer side (intercom)

    socket.emit("reject_call", {
      to: this.state.callFrom?.id,
      callId: this.state.callId,
      rejectMessage,
    });

    this.closeCall();
  }

  async handleAcceptCall() {
    const { callId, callFrom, isFromLobby } = this.state;
    try {
      this.pauseAudio();
      await updateCall(callId, { status: "CONTESTADA" });
    } catch (e) {
      console.error(e);
    }
    socket.emit("call_accepted", {
      to: callFrom?.id,
      callId: callId,
    });
    localStorage.setItem("callFrom", callFrom?.id);
    this.props.history.push(`/llamada-visitante/${callId}/${isFromLobby}`);
  }

  handleCloseModal() {
    console.log({ audio: this.audio });
    this.pauseAudio();
    this.setState({ showModal: false });
    this.handleRejectCall();
  }

  getCallingMessage() {
    const { callFrom, isFromLobby } = this.state;
    if (isFromLobby) {
      return `Te están llamando de Portería.`;
    }
    return `¡El apartamento ${callFrom?.name} te está llamando!, puedes contestar.`;
  }

  render() {
    const { showModal } = this.state;
    return (
      <Modal
        open={showModal}
        onClose={this.handleCloseModal.bind(this)}
        classNames={{ overlay: "background-modal" }}
        center
      >
        <div className="modal-dialog alerta" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                <LogoPeque />
              </h5>
              <button
                type="button"
                className="close"
                onClick={this.handleCloseModal.bind(this)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body calling">
              <h2>{this.getCallingMessage()}</h2>
            </div>
            <div className="modal-footer call-modal-footer">
              <button
                type="button"
                className="btn btn-success call-button"
                data-dismiss="modal"
                onClick={this.handleAcceptCall.bind(this)}
              >
                <span className="material-icons">call</span>
              </button>
              <button
                type="button"
                className="btn btn-danger call-button"
                data-dismiss="modal"
                onClick={() => {
                  this.handleRejectCall();
                }}
              >
                <span className="material-icons">call_end</span>
              </button>
            </div>
            <div
              className="modal-footer"
              style={{
                flexDirection: "column",
                justifyContent: "space-around",
                gridAutoColumns: "max-content",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <h5>
                  O puedes responder automáticamente con alguno de estos
                  mensajes...
                </h5>
              </div>
              {this.rejectMessages.map((rejectMessage, index) => (
                <button
                  key={`reject_call_${index}`}
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    display: "flex",
                    margin: "3px",
                    width: "100%",
                    whiteSpace: "normal",
                    textAlign: "left",
                  }}
                  type="button"
                  className="btn btn-outline-info"
                  data-dismiss="modal"
                  onClick={() => {
                    this.handleRejectCall(rejectMessage);
                  }}
                >
                  <span
                    className="material-icons"
                    style={{ marginRight: "5px" }}
                  >
                    mark_chat_read
                  </span>
                  {rejectMessage}
                </button>
              ))}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

IncomingCall.propTypes = {
  history: PropTypes.object,
};

export default withRouter(IncomingCall);
