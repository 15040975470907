import { url_base } from '../../config/url'
import axios from 'axios'

export function visitorAuthLogin (data) {
  return axios({
    method: 'post',
    url: `${url_base}/api/visitors/auth/login`,
    headers: {
      'Content-Type': 'application/json',
    },
    maxContentLength: 2000,
    maxBodyLength: 2000,
    data
    // cedula
    // password
  })
}

export function VisitorRegister (data) {
  return axios({
    method: 'post',
    url: `${url_base}/api/visitors`,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    maxContentLength: 2000,
    maxBodyLength: 2000,
    data
    // cedula
    // foto
  })
}

export function VisitorCreateVisit (cc, data) {
  return axios({
    method: 'post',
    url: `${url_base}/api/visitors/${cc}/visits`,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    maxContentLength: 2000,
    maxBodyLength: 2000,
    data,
  })
}
