import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { FormButton, FormInput } from '../../../../components/campo';
import { showErrorAlert } from '../../../../utils/alerts';
import { getErrorMessage } from '../CardManager/utils';

import { getUserByCedula } from '../../../../utils/services/blueBuildingService';
import { ListCards } from './ListCards';

import '../../../../assets/css/card-lector.css';
import { UserValidator } from './UserValidator';

export const VerifyUser = () => {
  const [userDocument, setUserDocument] = useState('');
  const [userStatus, setUserStatus] = useState('');
  const [userData, setUserData] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const fetchUserByDocument = useCallback(async () => {
    try {
      setIsLoading(true);
      const userData = await getUserByCedula(userDocument);

      const userStatus = userData.verified ? 'active' : 'inactive';
      setUserData(userData);
      setUserStatus(userStatus);
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      showErrorAlert('Error', errorMessage);
    } finally {
      setIsLoading(false);
    }
  }, [userDocument]);

  return (
    <>
      <h2>Registro de Tarjetas</h2>
      <div className="row d-flex justify-content-center">
        <div className="configuration-lector-container">
          <div className="row w-100">
            <div className="col-sm-12">
              <p>Ingrese la cédula del usuario que desea registar o actualizar.</p>
            </div>
          </div>
          <div className="skew-input w-100 mx-auto admin-input">
            <FormInput
              value={userDocument}
              onChange={(event) => {
                setUserDocument(event.target.value);
              }}
              name="cedula"
              id="cedula"
              type="text"
              placeholder="Cédula"
              required
            />
          </div>
          {!userData && (
            <div className="w-100 mx-auto justify-content-center">
              <FormButton
                type="button"
                textbutton="Consultar Usuario"
                onClick={fetchUserByDocument}
                disabled={!userDocument}
                isLoading={isLoading}
                className="admin-button"
              />
            </div>
          )}
          {userData.verified && (
            <div className="row w-100 admin-input">
              <div className="col-sm-12">
                <ListCards userDocument={Number(userDocument)} userId={userData.id} />
              </div>
            </div>
          )}
          {document && userStatus === 'inactive' && (
            <UserValidator cedula={Number(userDocument)} onUserValidated={fetchUserByDocument} />
          )}
        </div>
      </div>
    </>
  );
};
VerifyUser.propTypes = {
  cedula: PropTypes.number,
  userDocument: PropTypes.number,
  userId: PropTypes.string,
  onUserValidated: PropTypes.func,
};
export default withRouter(VerifyUser);
