import adapter from 'webrtc-adapter'
import _ from 'lodash'
import Emitter from './1-Emitter'
import { showErrorAlert } from '../utils/alerts'
console.log('adapter.browserDetails.browser', adapter.browserDetails.browser)
console.log('adapter.browserDetails.version', adapter.browserDetails.version)

/**
 * Manage all media devices
 */
class MediaDevice extends Emitter {
  /**
   * Start media devices and send stream
   */
  start () {
    const constraints = {
      video: {
        facingMode: 'user',
        height: { min: 360, ideal: 720, max: 1080 }
      },
      audio: { echoCancellation: true, noiseSuppresion: true }
    }

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.stream = stream
          this.emit('stream', stream)
        })
        .catch((err) => {
          console.log('MediaDevice -> start -> err', err)
          console.log('MediaDevice -> start -> err', err.name)
          if (err instanceof DOMException) {
            showErrorAlert('Error', `${adapter.browserDetails.browser} ${adapter.browserDetails.version}  no soporta video llamadas, por favor actualizar a la ultima version de ${adapter.browserDetails.browser}...`)
          } else if (err.name === 'NotAllowedError') {
            showErrorAlert('Error', 'Debes darle permisos a la cámara, para poder recibir la llamada.')
          } else {
            showErrorAlert('Error', err.message)
            console.log(err)
          }
        })
    } else {
      showErrorAlert('Error', `${adapter.browserDetails.browser} ${adapter.browserDetails.version} no soporta video llamadas, por favor actualizar a la ultima version de ${adapter.browserDetails.browser}.`)
    }

    return this
  }

  /**
   * Turn on/off a device
   * @param {String} type - Type of the device
   * @param {Boolean} [on] - State of the device
   */
  toggle (type, on) {
    const len = arguments.length
    if (this.stream) {
      this.stream[`get${type}Tracks`]().forEach((track) => {
        const state = len === 2 ? on : !track.enabled
        _.set(track, 'enabled', state)
      })
    }
    return this
  }

  /**
   * Stop all media track of devices
   */
  stop () {
    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop())
    }
    return this
  }
}

export default MediaDevice
