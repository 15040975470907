import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import defaultUser from '../../assets/img/default-user.svg';
import Header from '../../components/header';
import { VerifyUser } from './bb/CardManager/VerifyUser.jsx';
import Buildings from './Buildings.jsx';
import Apartments from './Apartments.jsx';
import AdminMenu from './AdminMenu.jsx';

import '../../components/form.css';
import '../../assets/css/adminDashboard.css';
import Camaras from '../camaras.js';

const AdminDashboard = (props) => {
  const { match } = props;

  return (
    <>
      <div className="App bg-blue flex-column">
        <div className="header-admin-dashboard">
          <Header userimage={defaultUser} />
        </div>
        <div className="row d-flex">
          <div className="col-12 mt-5 text-center text-white">
            <h1>Panel de Administración</h1>
          </div>

          <div className="row w-100 text-form">
            <div className="col-sm-12">
              <Route exact path={match.path} component={AdminMenu} />
              <Route path={`${match.path}/card-manager`} component={VerifyUser} />
              <Route exact path={`${match.path}/buildings`} component={Buildings} />
              <Route exact path={`${match.path}/buildings/:buildingId/apartments`} component={Apartments} />
              <Route exact path={`${match.path}/buildings/:buildingId`} component={Camaras} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
AdminDashboard.propTypes = {
  match: PropTypes.object,
};

export default AdminDashboard;
