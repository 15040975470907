import React, { Component } from 'react'
import PropTypes from 'prop-types'
/** CSS */
import '../assets/css/image-loader.css'

class ImageLoader extends Component {
  constructor (props) {
    super(props)
    this.inputRef = React.createRef()
  }

  handleChangeFile (event) {
    const files = event.target.files
    if (files && files.length > 0) {
      this.props.onImageLoaded(files[0])
    }
  }

  handleClickButton () {
    this.inputRef.current.click()
  }

  render () {
    const { label, previewImg } = this.props
    return (
      <div className='image-loader-container'>
        <input
          type='file'
          ref={this.inputRef}
          accept='image/*'
          style={{ visibility: 'hidden', width: 0, padding: 0 }}
          onChange={this.handleChangeFile.bind(this)}
        />

        <button
          type='button'
          className='btn btn-primary button-trigger'
          onClick={this.handleClickButton.bind(this)}
        >
          <span className='form-icon material-icons'>portrait</span>
          {label || 'Imagen'}
        </button>

        {previewImg ? (
          <div className='image-loader-container'>
            <img
              className='card-img-top'
              src={previewImg}
              alt='Card image cap'
            />
          </div>
        ) : null}
      </div>
    )
  }
}

ImageLoader.propTypes = {
  label: PropTypes.string,
  previewImg: PropTypes.string,
  onImageLoaded: PropTypes.func
}

export default ImageLoader
