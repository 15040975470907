import React, { Component } from 'react';
import { LogoPeque } from '../logo';
import { FormInput, FormButton } from '../components/campo.js'
import '../components/form.css';
import { Link } from 'react-router-dom';
import { userAuthForgotPassword } from "../utils/services/userService";
import { showErrorAlert, showSuccessAlert } from '../utils/alerts';

export class FormRecuperar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      cedula: ''
    }
  }

  render() {
    return (
      <div className="App bg-blue">
        <div className="container">
          <Link className="btn-back" to=""></Link>
          <LogoPeque />
          <form onSubmit={this.handleSubmit} className="row d-flex w-75 mx-auto justify-content-between">
            <div style={{
              fontSize: '1.3em',
              margin: '1em',
              color: '#FFFFFF'
            }} className="w-100 mx-auto">
              Ingrese su # de documento de indentidad.
            </div>
            <div className="skew-input input-shape-2 w-100 mx-auto">
              <FormInput value={this.state.cedula}
                onChange={this.handleInputChange}
                name="cedula"
                id="cedula"
                type="number"
                placeholder="123456789"
                required />
            </div>

            <FormButton type="submit" value="Iniciar" textbutton="Solicitar recuperar contraseña" className="btn w-100 mx-auto" />
          </form>

        </div>
      </div>
    );
  }

  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = async (event) => {
    const { cedula } = this.state;
    const obj = {
      cedula: cedula
    };
    event.preventDefault();
    if (this.state.cedula === '') {
      showErrorAlert('Formulario inválido', 'El campo cedula esta vacio');
    }
    else {
      try {
        await userAuthForgotPassword(obj)
        showSuccessAlert('Solicitud enviada','Revisa tu correo');
      } catch (error) {
        console.log(error)
      }
    }
  };
}

export default FormRecuperar;
