import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-responsive-modal';
import socket from '../utils/socket';

class CamaraModal extends Component {
  constructor(props) {
    super(props);
    this.errorCleaner = null;
    this.streamingIds = [];
    this.canvasRefs = {};

    /** Define dynamic Refs */
    const {
      door: { cameras },
    } = this.props;
    if (cameras && cameras.length > 0) {
      cameras.forEach((cam) => {
        this.canvasRefs[cam.id] = React.createRef();
      });
    }

    this.state = {
      isLoading: true,
      error: null,
      showModal: true,
      showOpenDoorButton: false,
      showCloseDoorButton: false,
    };
  }

  getFilePath(data) {
    const bytes = new Uint8Array(data);
    const blob = new Blob([bytes], { type: 'application/octet-binary' });
    return URL.createObjectURL(blob);
  }

  updateImage(camId, imagePath) {
    const canvas = this.canvasRefs[camId].current;
    if (canvas) {
      const context = canvas.getContext('2d');
      const img = new Image();
      img.onload = () => {
        context.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height);
      };
      img.src = imagePath;
    }
  }

  subscribeToStream() {
    const {
      buildingId,
      door: { cameras },
    } = this.props;

    if (cameras && cameras.length > 0) {
      cameras.forEach((cam) => {
        socket.emit('subscribe:cam_stream', { buildingId, camId: cam.id });
        const idStreaming = `cam_streaming_${cam.id}`;
        this.streamingIds.push(idStreaming);

        socket.on(idStreaming, (data) => {
          const path = this.getFilePath(data);
          this.updateImage(cam.id, path);
        });
      });
    }
  }

  componentWillUnmount() {
    const {
      buildingId,
      door: { cameras },
    } = this.props;
    /** Off streamings */
    if (cameras && cameras.length > 0) {
      cameras.forEach((cam) => {
        socket.emit('leave:cam_stream', { buildingId, camId: cam.id });
      });
      this.streamingIds.forEach((streamingId) => socket.off(streamingId));
    }
    socket.off('door_action:error');
  }

  componentDidMount() {
    this.subscribeToStream();

    let showOpenDoorButton = false;
    let showCloseDoorButton = false;
    if (this.props.door.localId) {
      showOpenDoorButton = true;
      if (this.props.door.localId.includes('$')) {
        showCloseDoorButton = true;
      }
      this.setState({
        showOpenDoorButton,
        showCloseDoorButton,
      });
    }

    socket.on('door_action:error', ({ error }) => {
      const message = error.message || JSON.stringify(error);
      console.error(message);
      this.setState({ error: message });
      /** Reset error */
      this.errorCleaner = setTimeout(() => {
        this.setState({
          error: null,
        });
      }, 5000);
    });
  }

  handleDoorAction(action) {
    if (this.errorCleaner) {
      clearTimeout(this.errorCleaner);
    }
    const buildingId = this.props.buildingId;
    const door = this.props.door.localId;

    socket.emit('door_action', { buildingId, door, action });
  }

  handleDeviceAction(action, command, operator) {
    if (this.errorCleaner) {
      clearTimeout(this.errorCleaner);
    }
    const buildingId = this.props.buildingId;
    const device = this.props.door.localId;

    socket.emit('device_action', {
      buildingId,
      device,
      action,
      command,
      operator,
    });
  }

  render() {
    const { showModal, error, showCloseDoorButton, showOpenDoorButton } = this.state;
    const {
      door: { cameras },
    } = this.props;
    return (
      <Modal
        open={showModal}
        onClose={this.props.onModalClosed.bind(this)}
        classNames={{ overlay: 'background-modal' }}
        center
      >
        <div className="modal-container">
          <button
            type="button"
            className="close modal-close"
            data-dismiss="modal"
            onClick={this.props.onModalClosed.bind(this)}
          >
            &times;
          </button>

          {Array.isArray(cameras) &&
            cameras.map((cam) => (
              <div key={cam.id} className="canvas-container">
                <span>{cam.name}</span>
                <canvas className="cam-canvas" ref={this.canvasRefs[`${cam.id}`]} />
              </div>
            ))}

          <div className="w-100 pb-1 mx-auto d-flex justify-content-center">
            {showOpenDoorButton ? (
              <button className="btn btn-primay open-door-button" onClick={this.handleDoorAction.bind(this, 200)}>
                Abrir Puerta
              </button>
            ) : null}
            {showCloseDoorButton ? (
              <button className="btn close-door-button" onClick={this.handleDoorAction.bind(this, 201)}>
                Cerrar Puerta
              </button>
            ) : null}
          </div>
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

CamaraModal.propTypes = {
  buildingId: PropTypes.string,
  door: PropTypes.object,
  onModalClosed: PropTypes.func,
};

export default CamaraModal;
