import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "./assets/css/App.css";
import "./components/form.css";
import { PrivateRoute } from "./helpers/2-private.routes";
import { SocketProvider } from "./contexts/socketContext.js";

import FormLogin from "./views/formlogIn";
import FormRecuperar from "./views/formrecuperar";
import FormRecovery from "./views/formrecovery";

import FormApartmentUserRegister from "./views/Apartment/userRegister";
import FormApartmentOwnerRegister from "./views/Apartment/ownerRegister";
import FormApartmentVisitorRegister from "./views/Apartment/visitorRegister";
import AdminDashboard from "./views/admin-dashboard/AdminDashboard";
import buildingDashboard from "./views/admin-dashboard/buildingDashboard";
import buildingDashboardVisitsCode from "./views/admin-dashboard/bluebuildingDashboardVisitsCode";
import buildingVisitsCode from "./views/admin-dashboard/bluebuildingVisitsCode";
import FormApartmentExpressVisitorRegister from "./views/Apartment/ExpressVisitorRegister";

import Acceso from "./views/acceso";
import AccesoVisitante from "./views/accesoVisitante.jsx";
import Llamada from "./views/llamada";
import LlamadaVisitante from "./views/llamadaVisitante";
import Profile from "./views/Profile";
import Layout from "./views/layout.js";

class App extends Component {
  render() {
    return (
      <SocketProvider>
        <Router>
          <Layout>
            <Switch>
              {/* <Route exact path='/' component={Home} /> */}
              <Route exact path="/" component={FormLogin} />
              <Route exact path="/login" component={FormLogin} />
              <Route exact path="/recuperar" component={FormRecuperar} />
              <Route exact path="/reset_password" component={FormRecovery} />

              <Route
                exact
                path="/apartment/owner/register"
                component={FormApartmentOwnerRegister}
              />
              <Route
                exact
                path="/apartment/user/register"
                component={FormApartmentUserRegister}
              />
              <Route
                exact
                path="/apartment/visitor/register"
                component={FormApartmentVisitorRegister}
              />

              <Route
                exact
                path="/apartment/visitor/express/register"
                component={FormApartmentExpressVisitorRegister}
              />

              <Route exact path="/visits/code" component={buildingVisitsCode} />

              <PrivateRoute
                exact
                path="/building/visits/code/confirm"
                component={buildingDashboardVisitsCode}
              />

              <PrivateRoute
                path="/building/dashboard"
                component={buildingDashboard}
              />

              <PrivateRoute path="/admin-dashboard" component={AdminDashboard} />

              <PrivateRoute exact path="/acceso" component={Acceso} />
              <PrivateRoute
                exact
                path="/acceso-visitante"
                component={AccesoVisitante}
              />
              <PrivateRoute exact path="/llamada" component={Llamada} />
              <PrivateRoute
                exact
                path="/llamada-visitante/:callId/:isFromLobby"
                component={LlamadaVisitante}
              />

              <PrivateRoute exact path="/profile" component={Profile} />
              <Redirect from="/camaras" to="/building/dashboard" />
            </Switch>
          </Layout>
        </Router>
      </SocketProvider>
    );
  }
}

export default App;
