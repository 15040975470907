import React, { Component } from 'react'
import PropTypes from 'prop-types'

class UnknownVisitorManager extends Component {
  constructor (props) {
    super(props)

    const formData = new FormData()

    this.state = {
      formData,
      previewPhoto: null,
      photosSrc: {
        face: null,
        id: null
      }
    }
  }

  componentDidUpdate (prevProps) {
    if (
      prevProps.photosSrc.face !== this.props.photosSrc.face ||
      prevProps.photosSrc.id !== this.props.photosSrc.id
    ) {
      this.setState({
        photosSrc: this.props.photosSrc
      })
      if (this.props.photosBlob) {
        this.state.formData.set('photo', this.props.photosBlob.face)
        this.state.formData.set('cedulaFrontal', this.props.photosBlob.id)
      }
    }
  }

  /** FORM */

  handleTakePhoto (type) {
    console.log('Taking photo from Unknown View')
    this.props.onTakePhotoEvent(type)
  }

  handleSelectVisitor (visitor) {
    this.props.onVisitorSelected(visitor)
  }

  render () {
    const { photosSrc } = this.state
    return (
      <div
        className='opc-llamada tab-content list-group'
        id='known-list-container'
      >
        <div className='tab-pane active' id='form-knowns'>
          <div className='buscador'>
            <button
              className='btn btn-primary back-btn'
              onClick={this.handleSelectVisitor.bind(this, null)}
            >
              <span className='material-icons'>arrow_back_ios</span>
            </button>
          </div>
          <div className='text-container'>
            <p>Toma las fotos de rostro y documento para permitir el ingreso</p>
          </div>
          <div className='panel-images-container'>
            <div
              className='photo-container'
              onClick={this.handleTakePhoto.bind(this, 'face')}
            >
              <span className='form-icon material-icons'>portrait</span>
              {photosSrc.face && (
                <img
                  className='face-photo'
                  src={photosSrc.face}
                  alt='Foto rostro conocido'
                />
              )}
            </div>
            <div
              className='photo-container'
              onClick={this.handleTakePhoto.bind(this, 'id')}
            >
              <span className='form-icon material-icons'>
                featured_play_list
              </span>
              {photosSrc.id && (
                <img
                  className='id-photo'
                  src={photosSrc.id}
                  alt='Foto documento conocido'
                />
              )}
            </div>
          </div>
          <div>
            <button type='button' className='btn btn-danger call-button' onClick={this.props.onCallEnded} >
              <span className='material-icons'>call_end</span>
            </button>
          </div>
        </div>
      </div>
    )
  }
}

UnknownVisitorManager.propTypes = {
  photosBlob: PropTypes.any,
  photosSrc: PropTypes.any,
  onTakePhotoEvent: PropTypes.func,
  onVisitorSelected: PropTypes.func,
  onCallEnded: PropTypes.func
}

export default UnknownVisitorManager
