import MediaDevice from "./MediaDevice";
import Emitter from "./1-Emitter";
import socket from "./../utils/socket";

const PC_CONFIG = { iceServers: [{ urls: ["stun:stun.l.google.com:19302"] }] };

class PeerConnection extends Emitter {
  /**
   * Create a PeerConnection.
   * @param {String} userId - ID of the friend you want to call.
   */
  constructor(callId, userId) {
    super();
    this.peerConnection = new RTCPeerConnection(PC_CONFIG);

    this.peerConnection.onicecandidate = (event) => {
      if (!event.candidate) {
        return;
      }
      socket.emit("webrtc_ice_candidate", {
        candidate: event.candidate,
        to: this.userId,
      });
    };

    this.peerConnection.ontrack = (event) => {
      console.log("****** ON TRACK");
      this.emit("peerStream", event.streams[0]);
    };

    socket.on("webrtc_ice_candidate", (data) => {
      this.addIceCandidate(data.candidate);
    });

    socket.on("webrtc_offer", async (data) => {
      await this.receiveOffer(data);
    });

    socket.on("webrtc_answer", async (data) => {
      await this.peerConnection.setRemoteDescription(data.answer);
    });

    this.mediaDevice = new MediaDevice();
    this.callId = callId;
    this.userId = userId;
    this.stream = null;
  }

  /**
   * Starting the call
   * @param {Boolean} isCaller
   * @param {Object} config - configuration for the call {audio: boolean, video: boolean}
   */
  start(isCaller, config) {
    this.mediaDevice
      .on("stream", (stream) => {
        this.emit("localStream", stream);
        this.stream = stream;

        stream.getTracks().forEach((track) => {
          this.peerConnection.addTrack(track, stream);
        });

        if (!isCaller) {
          this.startOffer();
        }
      })
      .start(config);

    return this;
  }

  /**
   * Stop the call
   * @param {Boolean} isStarter
   */
  stop(isStarter) {
    if (isStarter) {
      socket.emit("end", { to: this.userId, callId: this.callId });
    }

    this.mediaDevice.stop();
    if (this.stream) {
      this.stream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    this.peerConnection.close();
    this.peerConnection = null;
    socket.off("webrtc_ice_candidate");
    socket.off("webrtc_offer");
    socket.off("webrtc_answer");
    return this;
  }

  async startOffer() {
    const offer = await this.peerConnection.createOffer();
    await this.peerConnection.setLocalDescription(offer);
    socket.emit("webrtc_offer", { to: this.userId, offer });
  }

  async receiveOffer(data) {
    await this.peerConnection.setRemoteDescription(data.offer);
    const answer = await this.peerConnection.createAnswer();
    await this.peerConnection.setLocalDescription(answer);
    socket.emit("webrtc_answer", { answer, to: this.userId });
  }

  /**
   * @param {Object} candidate - ICE Candidate
   */
  addIceCandidate(candidate) {
    if (candidate) {
      const iceCandidate = new RTCIceCandidate(candidate);
      this.peerConnection.addIceCandidate(iceCandidate);
    }
    return this;
  }
}

export default PeerConnection;
