import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../assets/css/profile.css';
import socket from '../utils/socket';

export class InfoPerfil extends Component {
  logOut = () => {
    localStorage.clear();
    window.location.href = '/';
    socket.emit('disconnect', 'Logout')
  }

  render() {
    return (
      <div className="perfil">
        <div className="foto-perfil mx-auto">
          <div>
            <img src={this.props.userimage} style={{ width: '100%', height: '100%' }} />
          </div>
          <input type="file" name="fileupload" id="fileupload" />
        </div>
        <h2 id="username">{this.props.username}</h2>
        <p id="usercity">{this.props.usercity}</p>
        <p className="mt-2">Interior <span>{this.props.userinterior}</span>, Apartamento <span>{this.props.userapartamento}</span></p>
        <div id="userphone">{this.props.userphone}</div>
        <a onClick={this.logOut}>Cerrar sesión</a>
      </div>
    );
  }
}

InfoPerfil.propTypes = {
  userimage: PropTypes.string,
  username: PropTypes.string,
  usercity: PropTypes.string,
  userinterior: PropTypes.string,
  userapartamento: PropTypes.string,
  userphone: PropTypes.string
}

export default InfoPerfil;
