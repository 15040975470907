import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { showErrorAlert, showSuccessAlert } from '../../../../utils/alerts';
import socket from '../../../../utils/socket';
import { getBuildings } from '../../../../utils/services/buildingService';
import { FormButton, FormInput } from '../../../../components/campo';
import { getDoorsByBuildingId } from '../../../../utils/services/doorService';
import { createCard } from '../../../../utils/services/cardService';
import Modal from 'react-responsive-modal';

const CardRegister = ({ userId, onCardSave }) => {
  const [tag, setTag] = useState('');
  const [type, setType] = useState('');
  const [buildingId, setBuildingId] = useState('');
  const [buildings, setBuildings] = useState([]);
  const [doorId, setDoorId] = useState('');
  const [doors, setDoors] = useState([]);
  const [isConfiguringLector, setIsConfiguringLector] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const reset = () => {
    setTag('');
    setType('');
    setBuildingId('');
    setDoorId('');
    setShowModal(false);
  };

  const subscribeToCardReader = () => {
    try {
      socket.on('read_tag', (tagReaded) => {
        setTag(tagReaded);
      });
    } catch (error) {
      showErrorAlert('Error', error.message);
    }
  };

  const configLector = async () => {
    try {
      if (!buildingId || !doorId) {
        showErrorAlert('ups', 'Debes ingresar el edificio y el ID de la puerta');
        return;
      }
      setIsConfiguringLector(true);
      socket.emit('card:setup', { buildingId, door: doorId }).once('card:setup:response', ({ error }) => {
        if (error) {
          showErrorAlert('ups', error);
          setIsConfiguringLector(false);
          return;
        }
        showSuccessAlert('ok', 'Lector configurado correctamente');
        subscribeToCardReader();
        setIsConfiguringLector(false);
      });
    } catch (error) {
      showErrorAlert('Error', error.message);
    }
  };

  const loadBuildings = async () => {
    try {
      const buildings = await getBuildings();
      setBuildings(buildings);
    } catch (error) {
      showErrorAlert('Error', error.message);
    }
  };

  useEffect(() => {
    loadBuildings();
  }, []);

  const saveTag = async () => {
    setIsLoading(true);
    try {
      const response = await createCard({
        userId,
        tag,
        type,
      });

      showSuccessAlert('¡Excelente!', response.message);

      reset();
      onCardSave();
    } catch (error) {
      showErrorAlert('ups', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!buildingId) {
      return setDoors([]);
    }

    const loadDoors = async () => {
      try {
        const doorsResponse = await getDoorsByBuildingId(buildingId);
        setDoors(doorsResponse.doors);
      } catch (error) {
        showErrorAlert('Error', error.message);
      }
    };

    loadDoors();
  }, [buildingId]);

  return (
    <>
      <button
        type="button"
        className="btn w-100"
        onClick={() => {
          setShowModal(true);
        }}
      >
        Registrar tarjeta
      </button>
      <Modal open={showModal} classNames={{ overlay: 'background-modal' }} onClose={reset} center>
        <div
          className="modal-content"
          style={{ backgroundColor: '#00bfdf', borderRadius: '15px', padding: '10px 0px 30px' }}
        >
          <div className={'header-modal-new'}>
            <button type="button" className="button-close" onClick={reset}>
              <span aria-hidden="true" className="button-span">
                &times;
              </span>
            </button>
          </div>
          <div className={'body-modal-new'}>
            <div className="d-flex skew-input input-shape w-75 mx-auto justify-content-center">
              <select
                style={{ backgroundColor: '#00bfdf' }}
                id="buildingId"
                value={buildingId}
                name="buildingId"
                onChange={(event) => setBuildingId(event.target.value)}
              >
                <option value="">Selecciona un edificio</option>
                {buildings
                  .sort((buildingA, buildingB) => buildingA.name.localeCompare(buildingB.name))
                  .map((building) => (
                    <option key={building.id} value={building.id}>
                      {building.name}
                    </option>
                  ))}
              </select>
            </div>

            <div className="d-flex skew-input input-shape w-75 mx-auto justify-content-center">
              {buildingId && (
                <select
                  style={{ backgroundColor: '#00bfdf', paddingLeft: '20px' }}
                  id="doorId"
                  value={doorId}
                  name="doorId"
                  onChange={(event) => setDoorId(event.target.value)}
                >
                  <option value="">Selecciona una puerta</option>
                  {doors
                    .sort((doorA, doorB) => doorA.localId - doorB.localId)
                    .map((door) => (
                      <option key={door.id} value={door.localId}>
                        {door.localId} - {door.name}
                      </option>
                    ))}
                </select>
              )}
            </div>

            {!tag && (
              <div className="w-75 mx-auto d-flex justify-content-center">
                <FormButton
                  type="button"
                  textbutton="Configurar Lector"
                  onClick={configLector}
                  className="w-100 mx-auto admin-button"
                  isLoading={isConfiguringLector}
                  disabled={!buildingId || !doorId}
                />
              </div>
            )}

            {tag && (
              <div className="row w-100 admin-input">
                <div className="col-sm-12">
                  <div className="skew-input w-75 mx-auto mt-4">
                    <FormInput
                      value={tag}
                      onChange={(event) => {
                        setTag(event.target.value);
                      }}
                      name="tag"
                      id="tag"
                      type="text"
                      placeholder="Número de la tarjeta"
                      required
                    />
                  </div>

                  <div className="d-flex skew-input input-shape w-75 mx-auto justify-content-center">
                    <select
                      style={{ backgroundColor: '#00bfdf' }}
                      id="type"
                      value={type}
                      name="type"
                      onChange={(event) => {
                        setType(event.target.value);
                      }}
                    >
                      <option value="">Selecciona tipo</option>
                      <option value="CAR">CAR</option>
                      <option value="PERSON">PERSON</option>
                    </select>
                  </div>
                </div>
              </div>
            )}

            {tag && (
              <div className="row w-100 admin-input">
                <div className="col-sm-12">
                  <div className="w-75 mx-auto mt-4">
                    <FormButton
                      type="button"
                      textbutton="Registrar tarjeta"
                      onClick={() => {
                        saveTag();
                      }}
                      className="btn w-100 mx-auto button-form admin-button"
                      isLoading={isLoading}
                      disabled={isLoading || !tag || !type}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

CardRegister.propTypes = {
  onCardSave: PropTypes.func,
  userId: PropTypes.string,
};

export { CardRegister };
