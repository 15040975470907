const getSpanishError = ({ property, message }) => {
  const nullErrors = ["should not be null"];
  if (nullErrors.some((error) => message.includes(error)))
    return `El campo "${property}", es un campo obligatorio`;

  if (message.includes("already exists"))
    return `Este(a) "${property}", ya se encuentra registrado(a)`;

  // default Error message
  return `${property}: ${message}`;
};

export const getErrorsList = (error) => {
  const defaultErrors = ["Lo sentimos ha ocurrido un error"];
  if (!error) return defaultErrors;

  if (error.status === "401") {
    return ["No se encuentra autorizado para realizar esta acción"];
  }

  if (error.response?.data?.errors) {
    const errors = error.response?.data?.errors;

    return errors.map(getSpanishError);
  }
};
