import React, { Component } from 'react'
import PropTypes from 'prop-types'
/** Components */
import Logo from '../logo'
import Loader from '../components/loader';
import { Link } from 'react-router-dom'
import { FormInput, FormButton } from '../components/campo.js'
/** Services */
import socket from '../utils/socket'
import { userAuthLogin } from '../utils/services/userService'
import { blueBuildingAuthLogin } from '../utils/services/blueBuildingService'
import { validateMandatoryFields } from '../utils/validator'
/** CSS */
import '../index.css';
import { getLoggedPath } from '../utils/services/authService';

export class FormLogin extends Component {

  constructor(props) {
    super(props);
    this.state = {
      login: {
        cedula: '',
        password: '',
        userType: 'USUARIO'
      },
      errors: {},
      loading: false,
      userTypes: [
        {
          id: 'USUARIO',
          name: 'Ingresar como usuario',
          url: 'userAuthLogin'
        },
        {
          id: 'ADMIN',
          name: 'Ingresar como Administrador (bb)',
          url: 'blueBuildingAuthLogin'
        }
      ]
    }
  }

  componentDidMount() {
    if (localStorage.getItem('authToken')) {
      this.props.history.push('building/dashboard');
    }
    const cedula = localStorage.getItem('userCedula') || ''
    this.setState({
      login: {
        cedula
      }
    })

  }

  render() {
    const { login, errors, loading } = this.state;
    return (
      <div className="App bg-blue">
        <div className="container">
        <a onClick={this.goBack} className={"btn-back"} />
          <Logo className="App-logo w-75" />
          <form className="row d-flex w-75 mx-auto" onSubmit={this.handleSubmit}>

            <div className="skew-input input-shape w-100 mx-auto">
              <select value={login.userType}
                name="userType"
                onChange={this.handleInputChange}
                id="userType"
                required>
                {this.state.userTypes.map((userType) => <option key={userType.id} value={userType.id}>{userType.name}</option>)}
              </select>
            </div>

            <div className="skew-input w-100 mx-auto">
              <FormInput
                id="form-cedula"
                type="number"
                name="cedula"
                placeholder="Cédula"
                value={login.cedula || ''}
                onChange={this.handleInputChange}
                required />
            </div>
            {errors.cedula && (<div className="error-form-container">
              <span className="error-text">* Debes diligenciar la cédula</span>
            </div>)}

            <div className="skew-input input-shape-2 w-100 mx-auto">
              <FormInput
                id="form-password"
                type="password"
                name="password"
                placeholder="Contraseña"
                className="w-100"
                value={login.password || ''}
                onChange={this.handleInputChange}
                required />
            </div>
            {errors.password && (<div className="error-form-container">
              <span className="error-text">* Debes diligenciar la contraseña</span>
            </div>)}

            <div className="w-100 mx-auto d-flex justify-content-center">
              {loading ? (
                <Loader />
              ) : (
                <FormButton type="submit" value="Iniciar" textbutton="Iniciar" className="btn w-75 mx-auto button-form" />
                )}
            </div>

            {errors.invalidCredentials && (<div className="error-global-container">
              <span className="error-text">* Usuario o contraseña inválidos</span>
            </div>)}

            <div className="col-sm-12 link-option-container">
              <span>¿ Es usuario nuevo ? Regístrese como...</span>
              <div className="row w-100 text-form links-container">
                <div className="col-sm-4">
                  <Link className="link-option" to="apartment/owner/register">Líder/Propietario</Link>
                </div>
                <div className="col-sm-4">
                  <Link className="link-option" to="apartment/user/register">Residente/Empleado</Link>
                </div>
                <div className="col-sm-4">
                  <Link className="link-option" to="apartment/visitor/express/register">Visitante</Link>
                </div>
              </div>
            </div>

            <div className="col-sm-12 text-form">
              <Link className="link-option" to="recuperar">Olvidé mi contraseña</Link>
            </div>

          </form>
        </div>
      </div>
    )
  }

  goBack() {
    window.history.back()
  }

  handleInputChange = (event) => {
    this.setState({
      login: {
        ...this.state.login,
        [event.target.name]: event.target.value
      }
    })
  }

  handleSubmit = async (event) => {
    this.setState({
      loading: true
    })
    try {
      event.preventDefault()
      const { login } = this.state;

      const mandatoryFields = validateMandatoryFields(login, ['cedula', 'password', 'userType'])
      this.setState({ errors: mandatoryFields })
      if (!Object.keys(mandatoryFields).length) {
        let userlogged;
        if (login.userType === 'ADMIN') {
          userlogged = await blueBuildingAuthLogin(login)
        } else {
          userlogged = await userAuthLogin(login)
        }
        console.log(`FormLogin -> handleSubmit -> userlogged`, userlogged);
        this.setState({
          loading: false
        })
        await this.saveLoginDataStorage(userlogged)
      }
    } catch (error) {
      this.setState({
        loading: false
      })
      this.setState({ errors: { invalidCredentials: true } });
    }
  };

  async saveLoginDataStorage({ data }) {
    const { token, user, spaces } = data
    localStorage.setItem('authToken', token)
    localStorage.setItem('user', JSON.stringify(user))
    localStorage.setItem('userId', user.id)
    localStorage.setItem('userCedula', user.cedula)
    localStorage.setItem('userName', user.name)
    localStorage.setItem('userEmail', user.email)
    localStorage.setItem('userPhone', user.phone)
    localStorage.setItem('userType', user.userType)
    localStorage.setItem('spaces', JSON.stringify(spaces))

    socket.emit('init', { token })

    if (Array.isArray(spaces) && spaces.length > 0) {
      const {
        role,
        building,
        apartment
      } = spaces[0];

      localStorage.setItem('userRole', role.name)
      localStorage.setItem('buildingId', building.id)
      localStorage.setItem('buildingAddress1', building.address1)
      localStorage.setItem('buildingCity', building.city)
      localStorage.setItem('buildingName', building.name)
      localStorage.setItem('buildingState', building.state)
      localStorage.setItem('buildingType', building.type)

      localStorage.setItem('apartmentId', apartment.id)
      localStorage.setItem('apartmentName', apartment.name)
      localStorage.setItem('apartmentAddress', apartment.address)
      localStorage.setItem('apartmentType', apartment.apartmentType)
    }

    const path = await getLoggedPath(data);
    console.log(`%c ${path}`, 'color: green')
    this.props.history.push(path);
  }
}

FormLogin.propTypes = {
  history: PropTypes.object
}

export default FormLogin;
